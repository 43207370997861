$medium-width: map-get($breakpointSizes, 'medium');
$large-width: map-get($breakpointSizes, 'large');

@mixin small-only {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium-width}) and (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin make-square($size, $important: '') {
  width: $size #{$important};
  height: $size #{$important};
}

@mixin makeTypography($weight, $size, $lineHeight, $letterSpacing, $color) {
  font-family: 'Karla';
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  color: $color;
}

@mixin make-button-outlined($bg-color, $border-color, $importants: null) {
  border: 1px solid $border-color $importants;
  border-radius: $border-radius-1 $importants;
  background: $bg-color $importants;
}

@mixin make-scrollable($direction) {
  @if ($direction == 'vertical') {
    overflow-y: scroll;
  }
  @else if ($direction == 'horizontal') {
    overflow-x: scroll;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin make-scrollable-vertically() {
  @include make-scrollable('vertical');
}

@mixin make-scrollable-horizontally() {
  @include make-scrollable('horizontal');
}

@mixin add-vertical-margins($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@mixin remove-edge-paddings() {
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

@mixin make-indicator-circle() {
  @include make-square(6px);
  background-color: $red;
  border-radius: 50%;
}

@mixin make-product-icon($gradient-name: 'products-bar-design-selected-item') {
  &:before {
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    background-image: map-get($gradients, $gradient-name) !important;
    background-color: transparent;
    content: '';
    z-index: -1;
    border-radius: $border-radius-2;
  }
  &:after {
    content: '';
    position: absolute;
    right: -17px;
    border-radius: 2px 0 0 2px;
    width: 4px;
    height: 100%;
    background-color: $white;
  }
}
