.SpaceGridHeaderBar {
  box-sizing: border-box;
  background-color: $white;
  width: 100%;
  padding: map-get($gutters, 'xxs');
  display: flex;
  justify-content: center;

  &__button {
    justify-content: center;

    &-icon {
      border-right: 0 !important;
    }
  }
}
