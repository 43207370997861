

.SiteContainer {
  position: relative;
}
  
  .ProfileImage {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: red;
    justify-content: center;
    color: $white;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
  
    &__avatar {
      height: 100%;
      border-radius: 100%;
    }
  }

  .TestLink {
    
    background-color:red;
    margin: auto;
    height: auto;
    width: 100%;
    max-width: 680px;
    border-radius: 8px;
    margin-bottom: 16px;

      &-link {
        margin: 0px;
        border: none;
        text-align: center;
        cursor: pointer;
        background: none;
        text-decoration: none;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: auto;
        position: relative;
        padding: 16px 20px;
        width: 100%;
        appearance: none;
        box-sizing: border-box;
        vertical-align: middle;
      }
  }

  .round-image-wrapper {
      height: 232px;
      margin-top: -25px;
      z-index: 1;
      text-align: center;
  }
  .round-image {
      display: inline-flex;
      overflow: hidden;
      border-radius: 50%;

      img{
        height: 232px;
        width: 232px;
        object-fit: cover;
      }
  }

  .link-wrapper {
      margin: 0;
      height: 100%;
      width: 100%;
      padding-bottom: 80px;
      padding: 0px;
      border: 0px;
      vertical-align: baseline;
      overflow: hidden;
      margin-bottom: 16px;
      transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
      border: 1px solid red;
  }
  
  .link-wrapper a {
    margin: 0px auto;
    color: rgb(0, 0, 0);
    max-width: 680px;
    position: relative;
    height: auto;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
    background: none;
    color: inherit;
    transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
    padding-left: 66px;
    padding-right: 66px;
    border: none;
    text-align: center;
    cursor: pointer;
    background: none;
    text-decoration: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: auto;
    position: relative;
    padding: 16px 20px;
    width: 100%;
    appearance: none;
    box-sizing: border-box;
    vertical-align: middle;
    border: 1px solid purple;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
  
  }
  
  .link-wrapper .thumbnail {
       position: absolute;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      overflow: hidden;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 4px;
        display: flex;
        left: 4px;
      width: 46px;
      height: 46px;
  }
  .link-wrapper .thumbnail img {
      width: auto;
      height: 100%;
      display: block;
      object-fit: contain;
      object-position: initial;
    margin: 0px;
      padding: 0px;
      border: 0px;
      vertical-align: baseline;
  }
  
  .link-wrapper p {
      padding: 0px;
      margin: 0px;
      line-height: 1.5;
      width: 100%;
      border: 0px;
      vertical-align: baseline;
  }
  
  .social2-wrapper {
    padding-top: 16 px;
    padding-bottom: 16 px;
    display: flex;
    flex-direction: row;
    border: 1px solid green;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .social2-wrapper a {
      padding-bottom: 12px;
      padding-left: 8px;
      padding-right: 8px;
  }
  
  .social2-wrapper a svg {
      fill: rgb(0, 0, 0);
      width: 32px;
      height: 32px;
  }