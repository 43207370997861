$margin: map-get($gutters, 'xxs');

.Widget {
  &__top-bar {
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
    border-bottom: 1px solid $gray-2;
  }

  &__body {
    background: $white;
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
    height: calc(100% - 2 * #{map-get($gutters, 'm')});
  }

  &__title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__numeric-value {
    font-weight: bold;
    font-size: 30px;
    margin-top: $margin;
  }

  &__percentage {
    color: $gray-6;
    margin-top: $margin;

    &--positive {
      color: $green;
    }

    &--negative {
      color: $red;
    }

    &__suffix {
      color: $gray-6;
    }
  }
}
