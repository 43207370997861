$root-height: 28px;
$root-min-width: 50px;

.CommentsPreviewButton {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: $white;
  border: 0;
  border-radius: $border-radius-4;
  padding: 0 map-get($gutters, 'xxs');
  cursor: pointer;
  height: $root-height;
  min-width: $root-min-width;
  position: absolute;
  top: 6px;
  left: 6px;

  &:hover {
    background: $white;
    color: $black;
  }

  &__icon {
    @include make-square(16px);
  }

  &__number {
    margin-left: map-get($gutters, 'xxs');
    margin-bottom: map-get($gutters, 'xxxs');
  }

  &__unread-indicator {
    top: 2px;
    left: 37%;
  }
}
