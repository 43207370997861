$padding-l: map-get($gutters, 'l');
$caption-strategy-modal: '.CaptionStrategyModal';

#{$caption-strategy-modal} {
  width: $caption-strategy-modal-width;

  &__body {
    padding: $padding-l;
  }

  &__footer {
    #{$caption-strategy-modal}__cta {
      margin-bottom: $margin-1;
    }
  }
}
