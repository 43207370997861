.MarketPlaceContainer {
  text-align: center;
  padding: map-get($gutters, '50') 10vw;
  background: $white;

  &__title {
    margin-top: 0;

    @include small-only() {
      @include makeTypography(bold, 22px, 22px, -0.02em, $black);
    }
  }

  &__subtitle {
    color: $gray-6;
  }

  &__tiles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $margin-4;
  }

  &__loader {
    flex-basis: 100%;
    justify-content: center;
    margin-top: $margin-1;
  }
}
