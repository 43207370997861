.FontDropdown {
    position: relative;
    
    &-list{
      cursor: pointer;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      &:hover {
        background-color: "#ddd";
      }
    }
    &__input {
      padding:0;
      width: 40px;
      height: 36px;
      text-align: center;
      min-height: 0 !important;
    }
    &__label {
      display:none;
    }
  
    &__dropdown-content {
      position: absolute;
      background-color: #f6f6f6;
      overflow: auto;
      border: 1px solid #ddd;
      z-index: 1;
      max-height: 60vh;
    }

    &__background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__button {
        @include make-square(36px);
        border-radius: 1px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        display: inline-block;
        cursor: pointer;
    }
  }