.DeviceView {
    height: 100%;
    padding: 40px;

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0;
    
    }
    &__screen {
        background: rgb(255, 255, 255);
        position:absolute;
        &-content {
            overflow: auto;
            height:100%;
        }
    }
}