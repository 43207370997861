.IncompatibleSubscriptionNotificationModal {
  &__header {
    padding: map-get($gutters, 'l');
    height: map-get($gutters, 'l');
  }

  &__body {
    margin-top: -18px;
    padding: 0 map-get($gutters, 'xxl') map-get($gutters, 'xxl');

    @include medium-up() {
      width: 496px;
    }
  }

  &__closeIcon {
    color: $gray-3;
  }
}
