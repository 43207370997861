.TextA {
    margin: 21px auto;
    padding: 0 25px;
    position: relative;
    text-align: center;

    &__title{
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
        word-wrap: break-word;
    }
    &__description {
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}