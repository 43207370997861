.DateRangePresetsPicker {
  border-left: 1px solid $gray-2;

  &__option {
    margin: 0 !important;
  }

  &__item {
    padding: map-get($gutters, 's') map-get($gutters, 'm') map-get($gutters, 's') 0;
  }

  &__item--selected {
    background-color: $black;
    span {
      color: $white;
    }
  }
}
