.PaidMemberView {
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    padding-bottom: 100px;
    h3 {
        @include makeTypography(700, 26px, 100%, -0.02em, $white);
    }
    &__feature {
        padding: map-get($gutters,'s');
        padding-top: map-get($gutters,'l');
        &-container {
            display: flex;
            gap: map-get($gutters,'s');
            overflow: auto;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: map-get($gutters,'s')
        }
    }
    .header {
        color: white;
        text-align:center;
        padding: 32px;
        margin-top:20px;
        .product-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            row-gap:20px;
            column-gap:20px;
          svg {
            @include make-square(48px);
          }
        }
        h1 {
            @include makeTypography(700, 36px, 100%, -0.02em, $white);
        }
        p {
            @include makeTypography(400, 17px, 100%, -0.01em, #AAAAAA);
        }
    }
}