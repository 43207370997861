$dot-size: 1rem;
$dot-spacing: 1.5rem;

@keyframes dots-loader-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 $dot-size 0 -1.3rem;
  }
  40% {
    box-shadow: 0 $dot-size 0 0;
  }
}

.DotsLoader,
.DotsLoader:before,
.DotsLoader:after {
  @include make-square($dot-size);

  animation-fill-mode: both;
  animation: dots-loader-animation 1.8s infinite ease-in-out;
  vertical-align: text-bottom;
  border-radius: 50%;
}
.DotsLoader {
  display: none;
  position: relative;
  margin: -$dot-size auto 0 auto;
  transform: translateZ(0);
  animation-delay: -0.16s;
  color: $gray-4;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -$dot-spacing;
    animation-delay: -0.32s;
  }

  &:after {
    left: $dot-spacing;
  }

  &--visible {
    display: inline-block;
  }
}

