.ContentSwitch {
  &__switches {
    text-align: center;
    margin-top: $margin-2;
    margin-bottom: $margin-3;
  }

  &__switch {
    display: inline-block;
    margin-right: $margin-2;

    &:last-child {
      margin-right: 0;
    }
  }
}
