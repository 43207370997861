.IntegrationContainer {
  &__error {
    text-align: center;
    padding-top: map-get($gutters, 'l');
  }
  &__loader {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}
