.Snackbar {
  width: calc(100vw - 76px);

  @include medium-up() {
    width: auto;
  }

  position: fixed;
  left: 50%;
  top: 40px;
  display: flex;
  flex-direction: row;
  transform: translate(-50%, 0);
  padding: map-get($gutters, 's') map-get($gutters, 'l');
  align-items: center;
  background: $black;
  box-shadow: 0px 4px 16px rgba($black, 0.2);
  border-radius: 12px;
  color: $white;
  cursor: pointer;
  z-index: $zindex-snackbar;

  &__icon {
    margin-right: map-get($gutters, 'xxs');
    margin-top: map-get($gutters, 'xxxs');

    @include small-only() {
      margin-left: auto;
    }
  }

  &__text {
    @include small-only() {
      margin-right: auto;
    }
  }

  &--in,
  &--out {
    animation-duration: .3s;
    animation-timing-function: linear;
  }

  &--in {
    animation-name: fadeInOpacity;
    opacity: 1;
  }

  &--out {
    animation-name: fadeOutOpacity;
    opacity: 0;
  }

  &--hidden {
    display: none;
  }

  &--error {
    background: $red;
    color: $white;
  }

  a.link {
    color: $white;
    cursor: pointer;
    text-decoration: underline;
  }
}
