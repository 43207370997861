.Dropdown {
  position: relative;
  z-index: $zindex-dropdown;

  &__window {
    position: absolute;
    left: -16px;
    width: calc(100% + 32px);
    margin-top: map-get($gutters, "l");
    display: none;
    background-color: $white;
    box-shadow: 2px 2px 16px rgba($black, 0.08);
    border-radius: $border-radius-2;
    flex-direction: column;

    @include medium-up {
      width: calc(100% + 145px);
    }

    &--open {
      display: flex;
    }

    &--width-aligned {
      left: 0;
      width: 100%;
    }

    &--expand-top {
      bottom: 100%;
    }

    &--expand-bottom {
      top: 100%;
    }

    &--expand-right {
      top: 0;
      left: 100% !important;
    }
  }

  &__toggle {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 0;

    &__icon {
      &--right {
         padding-left: map-get($gutters, 's');
      }
      &--left {
        border: none;
        padding-right: map-get($gutters, 's');
      }
    }
  }

  &__content {
    width: 100%;
    padding: map-get($gutters, "m");
    box-sizing: border-box;
  }

  &__footer {
    border-top: 1px solid $gray-2;
    width: 100%;
    padding: map-get($gutters, "m");
    box-sizing: border-box;
  }
}
