.Comments {
  width: $comments-section-width;
  height: 100%;
  background-color: $white;
  color: $black;
  display: flex;
  flex-direction: column;

  &__content {
    padding: map-get($gutters, 'm') 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__form {
    border-top: 1px solid $gray-2;
    padding: map-get($gutters, 'm');
    display: flex;
    flex-grow: 0;
  }
}
