.UserMembership {
  @extend .TeamMembership;

  &>* {
    margin-bottom: map-get($gutters, "l")
  }

  &__free-plan-info {
    @extend .TeamMembership__trial-info;
  }

  &__readonly-input {
    @extend .TeamMembership__readonly-input;

    &-wrapper {
      @extend .TeamMembership__readonly-input-wrapper;
    }
  }
}
