.ImageEditorModal {
  margin-top: 5vh;

  &__body {
    width: 90vw;
    height: 90vh;
    max-width: 1116px;
    max-height: 642px;
  }
}
