$marketplace-tile-width: 336px;
$image-height: 263px;

.MarketPlaceTile {
  width: $marketplace-tile-width;
  min-width: $marketplace-tile-width;
  border-radius: $border-radius-3;
  margin: map-get($gutters, 'xs') map-get($gutters, 'xxs');
  cursor: pointer;

  @include medium-up() {
    min-width: auto;
  }

  &:hover {
    .MarketPlaceTile__image {
      transform: scale(1.05);
    }
  }

  &__image {
    transition: .1s all ease-in-out;
    border-radius: $border-radius-3 $border-radius-3 0 0;
    width: 100%;

    min-height: $image-height;
    object-fit: cover;
    object-position: top;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: $border-radius-3 $border-radius-3 0 0;
    height: $image-height;
  }

  &__footer {
    h4 {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    position: relative;
    text-align: left;
    padding: map-get($gutters, 'm');
    border: 1px solid $gray-2;
    border-top: 0;
    border-radius: 0 0 $border-radius-3 $border-radius-3;
    background: $white;
    z-index: $zindex-marketplace-tile-footer;
    box-shadow: 0px 3px 10px -7px $black-2;
  }

  &__avatar {
    @include make-square(20px);
  }

  &__credits {
    display: flex;
    align-items: center;
    margin-top: map-get($gutters, 'xxs');

    &-name {
      margin-left: map-get($gutters, 'xxxs');
      color: $gray-6;
    }
  }
}
