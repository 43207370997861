@mixin with-side-photo-container($container-name, $image-src) {
  #{$container-name} {
    display: flex;
    background: $white;
    height: 100vh;

    &__wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      @include medium-up() {
        width: calc(100% - 475px);
      }
    }

    &__logo {
      width: 100%;
      height: $signup-topbar-height;
      padding: map-get($gutters, 'l');
      box-sizing: border-box;

      @include medium-up() {
        padding: map-get($gutters, 'xxl');
      }
    }

    &__content {
      width: 100%;
      margin: 0;
      padding: 0 map-get($gutters, 'l');
      box-sizing: border-box;

      @include medium-up() {
        width: 475px;
        margin: auto;
      }
    }

    &__image {
      display: none;
      position: relative;
      z-index: 0;
      width: 475px;
      height: 100vh;
      margin-left: auto;
      background: url($image-src) center no-repeat;
      background-size: cover;

      @include medium-up() {
        display: flex;;
      }
    }

    &__caption-login {
      margin-top: 64px;
      letter-spacing: -0.01em;

      #{$container-name}__link {
        color: rgb(90, 135, 252);
      }
    }

    &__title {
      margin-bottom: 36px;

      h1 {
        margin-bottom: 4px;
      }

      span {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
    }

    .Spacer {
      margin: $margin-1 0;
    }

    &__input {
      margin-bottom: $margin-2;
    }

    .Button {
      margin-top: $margin-3;
    }
  }

  @content
}

@include with-side-photo-container('.LoginContainer', '../../assets/images/login-bg.png');
