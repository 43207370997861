.ButtonGroup {
  display: flex;
  flex-direction: row;

  >* {
    margin-right: map-get($gutters, "s");

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--between {
    justify-content: space-between;
  }
}
