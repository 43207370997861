.Card {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: 0.5px solid $gray-1;

  &--transparent {
    background: transparent;
  }

  &--bordered {
    box-sizing: border-box;
    padding: 29px map_get($gutters, 'l');
    border: 1px solid $gray-4;
    border-radius: $border-radius-1;
  }

  &__image {
    margin-right: map-get($gutters, 's');
  }
}
