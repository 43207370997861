.PublicProfileContainer {
  background: $white;

  &__wrapper {
    display: flex;
    margin: 0 map-get($gutters, 'xl');
    flex-direction: column;

    @include medium-up() {
      flex-direction: row;
    }
  }

  &__bg-image {
    width: 100%;
    height: 180px;
    overflow: hidden;

    img {
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &__avatar {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: $gray-5;
    color: $white;
    font-size: 58px;
  }

  &__sidebar {
    min-width: 100%;

    @include medium-up() {
      min-width: 325px;
      max-width: 325px;
    }
  }

  &__details {
    position: relative;
    box-sizing: border-box;
    padding-top: map-get($gutters, '60');
    padding-right: map-get($gutters, 'm');
    padding-left: map-get($gutters, 'm');
    word-break: break-all;
    box-sizing: border-box;
    min-height: 300px;
    background: $white;
    text-align: center;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 6px 4px -4px rgba(0, 0, 0, 0.08), 0px 16px 32px -16px rgba(0, 0, 0, 0.12);

    @include medium-up() {
      margin-top: -$margin-6;
    }

    &-item {
      color: $gray-6;
      margin: map-get($gutters, 'xxs') 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-joined {
      margin-top: $margin-2;
    }
  }

  &__badges {
    text-transform: uppercase;

    svg {
      margin-right: map-get($gutters, 'xxxs');
    }
  }

  &__badge {
    margin-right: map-get($gutters, 'xxs');

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__members {
    width: 100%;
  }

  &__avatar {
    @include make-square(120px);

    position: absolute;
    top: -$margin-6;
    left: calc(50% - 60px);
    margin: 0 auto;
    border: 4px solid $white;
  }

  &__loader {
    @extend .MarketPlaceContainer__loader;

    text-align: center;
  }

  &__content {
    @include small-only() {
      margin-top: $margin-2;
    }

    @include medium-up() {
      margin-left: $margin-2;
      padding: map-get($gutters, 'l') 0;
    }
  }

  &__tiles-container {
    margin-top: $margin-2;
    justify-content: start;
  }

  &__tile {
    max-width: 100% !important;
    min-width: 100% !important;
    margin-left: 0;
    margin-right: 0;

    @include medium-up() {
      margin: map-get($gutters, 'xs');
      min-width: auto !important;
      max-width: 322px !important;
    }
  }

  .Tabs__header {
    padding: 0 map-get($gutters, 'xs');
  }
}
