.PublicProfileUpdater {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__adjacent-fields {
    @include medium-up() {
      display: flex;
      flex-direction: row;

      form {
        width: calc(50% -  #{$margin-1} / 2);
        margin-right: $margin-1;
        margin-bottom: auto;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    @include small-only() {
      form {
        &:first-of-type {
          margin-bottom: $margin-1;
        }
      }
    }
  }
}
