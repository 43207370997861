.InsightsContainer {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: map-get($gutters, 'xl');

  @include medium-up() {
    flex-direction: row;
  }

  &__wrapper {
    width: 100%;
  }

  &__loadable {
    min-height: 100%;
  }
}
