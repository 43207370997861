$sides: (all, x, y, top, bottom, left, right);

@each $side in $sides {
  @if $side=='all' {
    .b-0 {
      padding: 0 !important;
    }
  }

  @else if $side=='x' {
    .b-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }

  @else if $side=='y' {
    .b-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
  }

  @else {
    $side-prefix: str-slice($side, 0, 1);

    .b-#{$side-prefix}-0 {
      border-#{$side}: 0 !important;
    }
  }
}
