$space-grid-width: 456px;
$space-grid-tile-width: 150px;

.SpaceGrid {
  position: relative;
  width: $space-grid-width;
  margin: 0 auto;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__infinite-loader {
    display: flex;
    justify-content: center;
  }

  &__tile {
    @include make-square($space-grid-tile-width);

    display: flex;
    background-color: $gray-2;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    margin: 1px;
    box-sizing: border-box;
    z-index: 1;

    &-wrapper {
      position: relative;
      width: 100%;
      z-index: 1;
    }

    &--readonly {
      cursor: auto;

      .SpaceGrid__tile-media-type-icon {
        top: auto;
        bottom: 10px;
      }
    }

    &--transparent {
      background-color: transparent;
    }

    &-loader {
      top: 0;
      left: 0;
      border: 2px solid $gray-1;
    }

    &-error {
      @extend .SpaceGrid__tile;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    &-selected-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      border: 1px solid $white;
      border-radius: 100%;
      background: $white;
    }

    &--selected {
      box-shadow: inset 0px 0px 0px 2px $white;
      border: 2px solid $black;
    }

    &-media-type-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $white;
    }

    &-publish-preview {
      @include make-square(28px);
      @include make-product-icon('products-bar-publish-selected-item');
      padding: map-get($gutters, 'xxxxs');
      display: flex;
      cursor: pointer;
      position: relative;
      border: 3px solid transparent;
      border-radius: $border-radius-2;
      background: $black;
      background-clip: padding-box;
      align-items: center;
      color: $white;
      top: map-get($gutters, 'xxxs');
      left: map-get($gutters, 'xxxs');

      &:after {
        display: none;
      }

      &-tooltip {
        max-width: 156px;
      }
    }

    &-uploading-icon {
      @include make-square(18px);
      color: $white;
      position: absolute;
      right: map-get($gutters, 'xxxs');
      margin-top: map-get($gutters, 'xxxs');
    }

    &-color-map {
      &-overlay {
        @include make-square(100%);
        position: absolute;
        top: 0;
        left: 0;
      }

      &-loader-wrapper {
        @include make-square(100%);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__action-bar {
    position: sticky;
    z-index: 2;
    bottom: $margin-1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $margin-3;
  }
}
