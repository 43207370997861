.HashtagElementWidget {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__rank {
        @include make-square(32px);
        background: $purple-1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;
        margin-bottom: map-get($gutters, 'xxs');
    }

    &__title {
        font-weight: bold;
        color: $black;
        margin-bottom: map-get($gutters, 'xxs');
        width: 100%;

        &-tooltip {
            color: $white;
        }
    }

    &__engagements {
        line-height: 120%;
        letter-spacing: -0.01em;
        color: $gray-6;
        width: 100%;
    }
}
