.SpacesLayoutContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  background: $gray-1;

  &__content {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }

  &__loadable {
    @extend .SpacesLayoutContainer;
  }

  &__spaces-sidebar {
    width: max-content;
    position: sticky;
    top: 0;
    align-self: flex-start;
    height: 100vh;
    z-index: $zindex-space-sidebar;
  }

  &--empty {
    .SpacesLayoutContainer__loadable {
      flex-grow: 0;
      justify-content: center;
    }
  }

  &__no-spaces-info {
    width: 320px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  &__paragraph {
    color: $gray-6;
    margin-bottom: $margin-2;
  }
}
