.ProfileInformation {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  &__section-title,
  &__section-subtitle {
    flex-basis: 100%;
    margin-top: 0;
  }

  @include medium-up() {
    padding: 40px 50px 50px;
  }

  &__fields {
    margin-left: 0;

    @include medium-up() {
      margin-left: 100px;
    }

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &>* {
      margin-bottom: map-get($gutters, "l")
    }

    &--public-profile {
      width: 100%;
      margin-left: 0;
    }
  }
}
