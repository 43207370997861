.TeamInformation {
  @extend .ProfileInformation;

  display: flex;
  flex-direction: column;
  padding: 0;

  &__wrapper {
    @include medium-up() {
      padding: map-get($gutters, '40') map-get($gutters, '50') map-get($gutters, '50');
    }
  }

  &__fields {
    @extend .ProfileInformation__fields;


    &--public-profile {
      margin-left: 0;
    }
  }

  &__section-title {
    @extend .ProfileInformation__section-title;
  }

  &__section-subtitle {
    @extend .ProfileInformation__section-subtitle;
  }

  &__delete-team {
    color: $red;
    margin-bottom: 0;
    font-weight: normal;
    text-transform: none;

    @include medium-up() {
      border-top: 1px solid $gray-2;
    }

    &-list {
      list-style: none;
      margin-left: 0;
      padding-left: 0;

      &-item {
        display: flex;

        &:before {
          content: '▸';
          padding-right: map-get($gutters, 'xxxs');
        }
      }
    }
  }
}
