$icon-wrapper-size: 28px;

.ErrorBlock {
  display: flex;
  border-radius: $border-radius-1;
  background: $red-3;
  color: $red;
  padding: map-get($gutters, 's') map-get($gutters, 'm');

  &__icon {
    @include make-square($icon-wrapper-size);
    min-width: $icon-wrapper-size;
    min-height: $icon-wrapper-size;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: map-get($gutters, 'xxs');
    color: $red;
  }
}
