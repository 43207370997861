.FeatureCard {
    flex: 0 0 auto;
    &__hero {
        width: 148px;
        overflow:hidden;
        border-radius: 15px;
    }
    &__text{
        color: white;
        padding: 5px;
    }
}
