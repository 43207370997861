.SpacesSidebar {
  width: 258px; // need to add 2px because of overflow: scroll-y
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: .1s width ease-in-out;
  border-right: 1px solid $gray-2;

  &__bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: $white;
    box-sizing: border-box;
    padding-left: map-get($gutters, 'xxxxs');
    overflow: hidden;

    &-footer {
      margin-top: auto;
      margin-bottom: map-get($gutters, 'xs');;

      .SpacesSidebar__list {
        border-bottom: 0;
      }
    }
  }

  &__toggler {
    display: flex;
    align-items: center;
    width: map-get($gutters, 's');
    height: 100%;
    background: rgba($gray-2, 0.6);
    cursor: pointer;
    box-shadow: inset -1px 0px 0px $gray-2, inset 1px 0px 0px $gray-2;

    &:hover {
      background: rgba($gray-2, 1);
    }
  }

  &__list {
    @include make-scrollable-vertically;

    list-style: none;
    margin: 0;
    padding: 0 map-get($gutters, 'xxs') map-get($gutters, 'm') map-get($gutters, 'xxxxs');
    border-bottom: map-get($gutters, 'atomic') solid $gray-1;
    overflow-x: hidden;
    min-height: calc(50% - 108px);

    &-bottom {
      border-bottom: 0;
    }

    &-item {
      margin: map-get($gutters, 'xxs') 0 map-get($gutters, 'xxs') map-get($gutters, 'xxxs');
      padding: map-get($gutters, 'xxs');
      border-radius: $border-radius-4;

      &:hover,
      &--selected {
        background: $gray-1;
      }

      &-icon {
        @include make-square(48px);
        min-width: 48px;
      }

      &-content {
        @include makeTypography(normal, 15px, 22px, 1.05px, $black);

        display: block;
        width: $expanded-spaces-sidebar-content-width;
        margin-left: map-get($gutters, 'xxxs');
      }

      &-spacesToAdd {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $gray-6;

        &__icon {
          border-radius: $border-radius-1;
          padding: map-get($gutters, 'xxs');
          margin-left: 5px;
        }
      }
    }

  }

  &__space-badge {
    @include make-square(48px);
    @include makeTypography(normal, 22px, 22px, -0.02em, $white);

    background: $black;
    border-radius: $border-radius-2;
    transition: .05s all ease-in;
    flex-shrink: 0;

    &-avatar {
      border-radius: $border-radius-2;
    }

    &:hover {
      box-shadow: 0px 0px 0px 4px $gray-6, inset 0px 0px 1px 1px rgba(0, 0, 0, 0.06);
    }

    &--selected {
      box-shadow: 0px 0px 0px 1px $white, 0px 0px 0px 4px $black, inset 0px 0px 1px 1px rgba(0, 0, 0, 0.06);
    }
  }

  &__smallIcon {
    @include make-square(20px);
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 50%;
    border: map-get($gutters, 'xxxxs') solid $white;
  }

  &__placeholder {
    border-radius: $border-radius-2;
    @include make-square(48px);

    &:hover {
      box-shadow: 0px 0px 0px 4px $gray-6, inset 0px 0px 1px 1px rgba(0, 0, 0, 0.06);
    }
  }

  &__loadable {
    @include make-scrollable-vertically;
    min-height: calc(50% - 90px);

    &-none {
      display: none;
    }
  }
}
