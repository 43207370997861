$schedule-post-media-adder-modal-width: 740px;
$schedule-post-media-adder-modal-min-height: 570px;

.SchedulePostMediaAdderModal {
  width: $schedule-post-media-adder-modal-width;

  &__body {
    padding: map-get($gutters, 'l');
    min-height: $schedule-post-media-adder-modal-min-height;
  }

  &__tabs-header {
    height: 100px;
    display: flex;
    margin-bottom: $margin-1;
    border-bottom: 0;
    padding: 0;
  }

  &__tab {
    &-label {
      width: 33%;
      @include medium-up() {
        @include make-square(100px);
      }
      margin: 0 map-get($gutters, 's') 0 0;
      border: 1px solid $gray-3;
      border-radius: $border-radius-1;
      box-sizing: border-box;

      &--active {
        background: $black;
        color: $white;
        border-color: $black;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-icon {
      margin-bottom: $margin-1;
    }
  }

  &__device {
    position: relative;
    background: $gray-1;
    border: 2px solid transparent;
    border-radius: $border-radius-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;

    &--drag-active {
      border: 2px dashed $black;
      background: $white;
    }

    &-separator {
      margin: $margin-1 0;
      color: $gray-5;
      text-transform: uppercase;
    }
  }

  &__upload-icon {
    color: $gray-4;
    width: 100px;
    margin-bottom: map-get($gutters, 'l');

    &--black {
      color: $black;
    }
  }

  &__archive {
    @include make-scrollable-vertically;

    width: 100%;
    height: 400px;

    &-wrapper {
      flex-flow: row wrap;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.Loadable--loading {
        height: 400px;
      }
    }
  }

  &__error {
    position: absolute;
    padding: map-get($gutters, 'm');
    margin-top: 300px;
    text-align: center;
    color: $red;

    @include medium-up() {
      text-align: left;
      padding: 0;
    }
  }

  &__loader {
    position: fixed;
  }

  &__image {
    cursor: pointer;
    max-width: 100%;
    height: 140px;
    transition: .2s all ease-in-out;
    opacity: 0.95;
    border: 2px solid transparent;
    object-fit: cover;
    object-position: center;

    @include medium-up() {
      height: 200px;
    }

    &:hover {
      opacity: 1;
      border: 2px solid rgba($black, 0.3);
    }

    &-wrapper {
      margin: map-get($gutters, 'xxxs');
      position: relative;
      text-align: center;
      max-width: 46%;
      flex-basis: 46%;

      @include medium-up() {
        max-width: 33%;
        flex-basis: 30%;
      }
    }
  }

  &__spaces {
    @include make-scrollable-vertically;
    height: 400px;

    &--with-media {
      height: 520px;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__space {
    border: none;
    flex-basis: 100%;
    flex-direction: row;
    padding: map-get($gutters, 's');
    margin: map-get($gutters, 'xs') 0;
    transition: .1s background-color ease-in;
    cursor: pointer;
    border-radius: $border-radius-1;

    @include medium-up() {
      flex-basis: 45%;

      &:nth-child(2n+1) {
        margin-left: map-get($gutters, 'm');
      }
    }

    &:hover {
      background-color: $gray-1;
    }

    &-badge {
      &:hover {
        box-shadow: none;
      }
    }
  }
}
