.AddFacebookAccountModal {
  @extend .AddInstagramAccountModal;

  &__body {
    @extend .AddInstagramAccountModal__body;
    text-align: left;
  }

  &__accounts-list {
    margin-top: $margin-2;

    &-option {
      margin-top: 0 !important;
    }

    &-item {
      padding: map-get($gutters, 'xxs') 0;

      &:first-child {
        border-top: 1px solid $gray-2;
        border-bottom: 0;
      }

      border-bottom: 1px solid $gray-2;
    }
  }

  &__tick-icon {
    @include make-square(20px);
  }

  &__account-types {
    margin-top: $margin-2;

    &-icon {
      @include make-square(24px);
      margin-right: map-get($gutters, 'xxxs');
    }

    &-description {
      width: 365px;
    }
  }

  &__footer {
    @extend .AddInstagramAccountModal__footer;
  }
}
