.NoDataWidget {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &__icon {
    @include make-square(48px);
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: $gray-2;
    margin-top: $margin-1;

    &--alert {
      @include make-square(28px);
      top: map-get($gutters, 'xxs');
      position: relative;
    }
  }

  &__text{
    padding-top: map-get($gutters, 'm');
    color: $gray-5;
    font-size: 20px;
  }
}
