.MarketplaceBadge {
  @include make-square(40px);

  color: $white;
  border-radius: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background: unquote(map-get($gradients, 'marketplace-badge'));

  &__icon {
    width: 18px;
  }
}
