.SpaceTitleBar {
  position: sticky;
  top: 0;
  z-index: $zindex-space-title-bar;
  width: 100%;
  height: $space-title-bar-height;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 map-get($gutters, 'm');
  box-sizing: border-box;
  border-bottom: 1px solid $gray-2;

  &__space-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}
