$imageSize: 160px;

.ContentEditor {
  display: flex;
  flex-direction: row-reverse;

  &__container {
    flex: 1;
    margin-left: map-get($gutters, 'l');
  }

  .CaptionEditorWrapper {
    flex: 1;

    .CaptionEditor {
      flex: 1;
      padding-left: 0;
      border: 0px;

      &--focused {
        border: 0;
      }

      &__textarea {
        background: $gray-1;
        min-height: 177px;
        border: 1px solid transparent;

        &--focused {
          border: 1px solid $black;
          background-color: $white;
        }
      }

      &__title {
        padding-bottom: map-get($gutters, 'xxxs');
        font-size: map-get($gutters, 's');
        font-weight: 700;
      }
    }
  }

  .SchedulePostMediaAdder {
    margin-top: 3px;
    @include make-square($imageSize);

    &__image {
      @include make-square($imageSize);
    }

    &__error {
      width: $imageSize;
    }
  }
}
