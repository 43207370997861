.Spacer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__line {
    border-bottom: 1px solid $gray-5;
    height: 1px;
    width: 100%;
  }

  &__text {
    font-family: Karla;
    color: $gray-5;
    padding: 0 19px;
    font-weight: 600;
    font-size: 13px;
  }
}
