.InsightsGrid {
  &__grid-item {
    background: $white;
  }

  .react-grid-item.react-grid-placeholder {
    background: $gray-4;
    opacity: 1;
  }

  .react-grid-item.cssTransforms {
    transition-property: none;
  }

  .animated .react-grid-item.cssTransforms {
    transition-property: transform;
  }
}
