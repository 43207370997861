.FacebookPrivateGroupWarningModal {
  width: $caption-strategy-modal-width;

  &__header {
    padding: map-get($gutters, 'l');
    padding-bottom: 0;
    height: auto;
  }
  &__title {
    text-transform: none;
    font-size: 20px;
  }

  &__body {
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
  }

  &__description {
    margin: 0;
    margin-bottom: $margin-1;
  }

  &__link {
    margin: 0;
    & > a {
      color: $blue;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
  }

  &__checkbox {
    display: flex;
    align-items: center;
  }
}
