$padding-l: map-get($gutters, 'l');
$padding-m: map-get($gutters, 'm');
$padding-s: map-get($gutters, 's');

.CaptionToolModal {
  width: $add-new-space-modal-width;

  &__footer {
    @extend .ConfirmationModal__body;

    padding-bottom: $padding-l;
    display: flex;
    justify-content: flex-end;

    &-button {
      margin-left: $padding-m;
    }
  }

  &__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $black;
    position: relative;
    min-height: 210px;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 60vh;
    }
  }

  &__album-indicator-icon {
    position: absolute;
    top: $padding-s;
    right: $padding-s;
    color: $white;
  }

  &__expand-icon {
    color: $white;
    position: absolute;
    bottom: $padding-s;
    right: $padding-s;
    cursor: pointer;
  }

  &__textarea {
    width: 100%;
    height: 160px;
    padding: $padding-m $padding-l;
    box-sizing: border-box;
    border: 0;
    outline: none;
    resize: none;
  }
}
