.PublishContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include medium-up() {
    flex-direction: row;
  }

  &__wrapper {
    width: 100%;
    @include small-only() {
      margin-top: $margin-1;
      margin-bottom: auto;
    }
  }

  &__schedule-post-button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 map-get($gutters, 's');

    @include medium-up() {
      width: 480px;
      margin: $margin-1 auto 0 auto;
      padding: 0;
    }

    & + .PublishContainer__empty-queue {
      margin-top: 0;
      @include medium-up() {
        margin-top: -#{map-get($gutters, '50')};
      }
    }
  }

  &__accounts {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: map-get($gutters, 'xxs') map-get($gutters, 'xxs');

    @include medium-up() {
      display: flex;
      flex-direction: column;
      width: 360px;
      padding: 0 map-get($gutters, 'xxs');
    }
    background: $white;
    box-sizing: border-box;
    box-shadow: inset -1px 0px 0px $gray-2;

    &-loadable {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 24px;
    }

    &-list {
      @include medium-up() {
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-2;
      }
    }

    &-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 64px;

      @include medium-up() {
        width: 100%;
      }
    }

    &__title {
      margin-left: map-get($gutters, 'xxs');
    }

    &-settings {
      margin-left: auto;
      padding-right: map-get($gutters, 'xxs');

      &-icon {
        border-right: 0;
        color: $gray-6;
      }
    }

    &-button {
      overflow: hidden;
      padding-left: map-get($gutters, 'xxs');
      text-transform: none;
      font-weight: normal;

      @include small-only() {
        width: 44px;
        padding: 0;
      }

      @include medium-up() {
        border-top: 1px solid $gray-2;
        padding-top: map-get($gutters, 'l');
        margin-top: map-get($gutters, 'm');
      }
    }

    &-add-account-button {
      margin-top: 0;
      @include medium-up() {
        margin-top: map-get($gutters, 's');
      }

      &-icon-svg {
        @include small-only() {
          @include make-square(32px, '!important');
        }
      }
    }
  }

  &__empty-queue {
    @extend .ForTeamOnly;

    &-title {
      margin-bottom: map-get($gutters, 'xxxs');
    }

    &-text {
      color: $gray-6;
    }

    &-button {
      margin-top: $margin-1;
    }
  }
}
