.TeamInvoicesModal {
  &__loadable {
    display: flex;
    flex-direction: column;
  }
  &__body {
    max-width: 100% !important;
    padding: 0 map-get($gutters, 'm');
    margin-top: 0 !important;

    @include medium-up() {
      padding: 0 160px;
      margin-top: 45px !important;
    }
  }
  &__export-button {
    margin-left: 0;
    max-width: 190px;

    @include medium-up() {
      margin-left: auto;
      max-width: 240px;
    }
  }
  &__title {
    font-size: 17px;

    @include medium-up() {
      font-size: 22px;
    }
  }

  &__table {
    @include medium-up() {
      margin-top: 56px;
    }
    font-size: 15px;

    &-column {
      padding: 21px 0;
    }

    &-head-column {
      padding: 24px 0;
    }
  }
}
