.PaywallTile {
  background-color: $gray-1;
  box-sizing: border-box;
  border-radius: $border-radius-2;
  display: flex;
  flex-direction: column;
  padding: map_get($gutters, 'l');
  border: 2px solid $gray-1;
  text-align: left;

  @include medium-up() {
    width: 226px;
    height: 389px;
  }

  &--highlighted {
    background: $white;
    border: 2px solid $black;
  }

  &__title {
    margin-top: 0;
  }

  &__subtitle {
    font-size: 13px;

    @include medium-up() {
      // @TODO: Equalize height in react. This is temporary solution.
      height: 60px;
    }
  }

  &__price {
    margin-top: 18px;

    &-amount {
      font-size: 26px;
    }

    &-period {
      font-size: 15px;
    }
  }

  &__perk {
    margin: 0 0 map-get($gutters, "xxs") 0;
    padding: 0;
    min-width: auto;

    &-icon {
      height: 18px;
      margin-right: 0;
    }
  }

  &__perks {
    &-title {
      margin-top: $margin-2;
    }
  }

  &__footer {
    margin-top: auto;
  }
}
