.SchedulePostButton {
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $gray-2;
  padding: map-get($gutters, 'm');
  color: $gray-6;
  cursor: pointer;
  border-radius: $border-radius-1;
  transition: .2s all ease-in-out;

  &:hover {
    border: 1px solid $gray-4;
    color: $gray-7;
  }

  &__icon {
    margin-left: auto;
    color: $black;
  }
}
