.SiteBuilderContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    height:100vh;
    background: $gray-1;
  
    &__loadable {
      @extend .SiteBuilderContainer;
    }
  
    &--empty {
      .SiteBuilderContainer__loadable {
        flex-grow: 0;
        justify-content: center;
      }
    }
  
    &__no-spaces-info {
      width: 320px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  
    &__paragraph {
      color: $gray-6;
      margin-bottom: $margin-2;
    }
  }
  