$space-grid-width: 352px;
$space-grid-tile-width: 85px;

.EmptySpaceGrid {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__wrapper {
    width: 100%;
    text-align: center
  }

  &__image {
    margin-bottom:  map-get($gutters, 'xs');
    width: 80px;
  }

  &__info {
    $gutter-90: map-get($gutters, '90');
    position: relative;
    text-align: center;
    margin-top: -$gutter-90;
    padding-top: $gutter-90;
    font-size: 20px;

    &-title {
      font-size: 20px;
      margin: 6px 0;
    }
  }

  &__description {
    color: $gray-6;
    margin: 8px 0;
  }

  &__action-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: map-get($gutters, 'l');
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      margin-right: 0;
    }

    &-text {
      margin-left: map-get($gutters, 'xs');
    }
  }

  &__button {
    margin-bottom: map-get($gutters, 'xs');
    border-radius: 24px;
    text-transform: unset;
    font-size: 16px;

    &:hover {
      filter: invert(1)
    }
  }
}
