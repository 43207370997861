$padding-l: map-get($gutters, "l");

.PaywallTileUnavailableModal {
  max-width: $plan-unavailable-modal-max-width;
  padding: $padding-l $padding-l 0 $padding-l;

  &__heading {
    margin-top: 0;
    display: flex;
  }

  &__warning {
    color: $yellow;
    padding: map-get($gutters, "xxxs") map-get($gutters, "xxs") 0 0;
  }

  &__list {
    padding-left: 0;
    list-style: none;

    li {
      padding-bottom: map-get($gutters, "xxxs");
    }

    li:before {
      content: "▶";
      font-size: 6px;
      margin-right: 4px;
      position: relative;
      bottom: 3px;
    }
  }
}
