$selectable-list-gutter: map-get($gutters, 'xxs');

.TargetAccountChooser {
  &__label {
    @extend .Input__label;
  }

  &__message {
    @extend .Input__message;
  }

  &--error {
    .TargetAccountChooser__message {
      color: $red;
    }
  }

  &__selectable-list {
    @include make-scrollable-vertically;

    border-top: 1px solid $gray-2;
    margin-top: $selectable-list-gutter;
    margin-bottom: $selectable-list-gutter;
    padding-top: $selectable-list-gutter;
    padding-bottom: $selectable-list-gutter;
    height: 216px;
  }

  &__not-selected {
    @include make-square(18px)
  }
}
