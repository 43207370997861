.FeatureSection {
    padding-top: map-get($gutters,'xl');
    background: url('https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/gradient-blue-purple.png');
    background-size: cover;
    h2 {
        @include makeTypography(700, 18px, 100%, -0.02em, $white);
    }

    &__text {
        padding: 0 map-get($gutters,'m');
        padding-top: map-get($gutters,'l');
        h1 {
            @include makeTypography(700, 36px, 100%, -0.02em, $white);
        }
        &-description {
            @include makeTypography(400, 15px, 120%, -0.01em, $white);
            padding-bottom: map-get($gutters,'xl');
        }
    }
    &__content {
        align-items: center;
        justify-content: center;
        row-gap:20px;
        column-gap:10px;
        &-graphic {
            text-align: center;
            padding-top: map-get($gutters,'l');
        }
    }
}