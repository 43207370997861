.NotificationDropdown {
  button {
    width: 100%;
  }

  &__window {
    @include make-scrollable-vertically;
    max-height: 400px;
    width: 80vw;
    margin-left: 100px;

    @include medium-up() {
      max-height: calc(100vh - 200px);
      width: 320px;
    }
  }

  &__trigger {
    display: flex;
    flex-direction: row;
    margin: map-get($gutters, 'xxxs') 0 map-get($gutters, 'xxxs') 21px;
    color: $white;
    align-items: center;

    @-moz-document url-prefix() {
      margin-left: 23px;
    }

    &-text {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-icon {
      margin-left: auto;
    }
  }

  &__icon {
    width: auto;
    marign-right: 10px;
  }

  &__badge {
    align-self: start;
  }

  &__avatar {
    height: 100%;
    border-radius: 50%;
  }
}
