.SocialA {
    &__container-1 {
        height:100%
    }
    &__container-2 {
        height:100%
    }
    img {
        height:100%
    }
}
