.Tabs {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__dropdown {
    border: 1px solid $gray-4;
    border-radius: $border-radius-1;

    .Dropdown {
      &__toggle {
        border-radius: $border-radius-1 $border-radius-1 0 0;
        background-color: $white;
        width: 100%;
        padding: 17px map-get($gutters, 'm');
        font-size: 15px;
        font-weight: bold;
        border-bottom: 0.5px solid $gray-4;
      }

      &__window--open {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        margin-top: 0;
      }

      &__content {
        padding: 0;
      }
    }

    .Tabs__list-option {
      padding: map-get($gutters, 'm');
      border-bottom: 0.5px solid $gray-4;
      margin: 0 !important;
    }

    .Tabs__list-item-description {
      margin: 0;
    }
  }

  &__header {
    height: 60px;
    border-bottom: 0.5px solid $gray-1;
    padding: 0 50px;
  }

  &__label {
    color: $gray-6;
    font-weight: normal;
    background-color: transparent;
    height: 100%;
    border: none;
    border-radius: 0;
    margin-right: $margin-5;
    padding: 0;
    cursor: pointer;
    outline: 0px;
    -webkit-appearance: none;
    box-shadow: none;

    &--active {
      color: $black;
      font-weight: bold;
      border-bottom-color: $black;
      border-bottom-style: solid;
      border-bottom-width: 0.5px;
    }
  }

  &__dropdown-toggle {
    display: flex;
    align-items: center;

    &-chevron {
      margin-left: auto;
    }
  }

  &__card {
    margin-top: map-get($gutters, 'm');
  }
}
