.AddCommentForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__author {
    display: flex;
    align-items: center;
  }

  &__avatar {
    @include make-square(40px)
  }

  &__name {
    color: $black;
    margin-left: map-get($gutters, 'm')
  }

  &__form {
    display: flex;
    align-items: center;
    margin-top: map-get($gutters, 'xxs');
  }

  &__field {
    display: flex;
    flex-grow: 1;
    padding: map-get($gutters, 'xxs') map-get($gutters, 's');
    border-radius: $border-radius-1;
    border: 0;
    background: $gray-1;
    outline: none;
    resize: none;
  }

  &__submit-button {
    background: transparent;
    border: 0;
    color: $black;

    &--disabled {
      color: $gray-3;
    }
  }
}
