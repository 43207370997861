.SiteBuilderTitleBar {
    position: sticky;
    top: 0;
    z-index: $zindex-space-title-bar;
    width: 100%;
    height: $space-title-bar-height;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map-get($gutters, 'm');
    box-sizing: border-box;
    border-bottom: 1px solid $gray-2;
  
    &__actions {
      display: flex;
      gap: map-get($gutters,'xs');
      align-items: center;
    }
  }
  