.UserBadge {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: transparent;
  justify-content: center;
  color: $white;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;

  &__avatar {
    height: 100%;
    border-radius: 100%;
  }
}
