.List {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    min-width: max-content;

    &:last-child {
      margin-bottom: 0;
    }

    &--clickable {
      cursor: pointer;
      transition: .125s background-color ease-in-out;

      &:hover {
        background-color: $gray-1;
      }
    }

    &-icon {
      display: flex;
      width: 36px;
      min-width: 36px;
      height: 36px;
      margin-right: 16px;
      align-items: center;
      justify-content: center;
    }

    &-content {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.01em;
      width: 100%;
    }

    &-link {
      text-decoration: none;
    }

    &-description {
      font-size: 11px;
      line-height: 13px;
      color: $gray-6;
    }
  }

  &--simplified {
    .List__item {
      padding: 0;
      margin-bottom: 0;

      &-icon {
        margin-right: 0;
      }

      &-content {
        font-size: 16px;
      }
    }
  }

}
