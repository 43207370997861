.SelectableListItem {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  box-sizing: border-box;

  &__description {
    margin-left: map-get($gutters, 'xxs');
    margin-right: map-get($gutters, 'xxxs');
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__actions {
    margin-left: auto;
  }

  &__icon-wrapper {
    margin-left: auto;
  }

  &__indicator {
    @include make-indicator-circle();
    flex-shrink: 0;
    align-self: start;
  }

  &__error {
    min-width: 100%;
  }

  &--with-error {
    flex-wrap: wrap;
    background: $red-3;
    border-radius: $border-radius-1;
  }
}
