.SiteBuilderAsset {
    width:320px;
    height:100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    
    &__nav {
        width: 100%;
        margin-top: 10px;
        height: 54px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F5F5F5;
    }

    &__list{
        &-icon {
            background-color: black;
            border-radius: 4px;
            width:30px;
            height:30px;
            color: white;
            padding: 10px;
            svg {
               width:100%;
               height:100% 
            }
        }
        &-left-wrapper {
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
} 