.SocialMediaList {
    padding: map-get($gutters,'l') 0;
    &__table {
      display:flex;
      flex-wrap: wrap;
      padding: map-get($gutters,'xs') 0;
    }
      &__listview {
        cursor: pointer;
        padding:3px;
        display: flex;
        width: calc((100% / 3) - 11px);
        border: 1px solid black;
        &-item {
          padding:3px;
          width:20px;
          height:20px;
          img {
              width:100%;
              height:100%;
          }
        }
      }
  }