.SpaceLayoutChooserModal {
  width: $add-new-space-modal-width;

  &__body {
    min-height: 452px;
    padding: 0 $padding-l;
  }

  &__tiles {
    padding-top: map-get($gutters, 'm');
    max-height: 540px;
  }
}
