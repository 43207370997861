.ConfirmCancellationModal {
  &__header {
    height: auto;
    padding-top: map-get($gutters, 'l');
    padding-bottom: map-get($gutters, 'm');
    padding-right: map-get($gutters, 'xxl');

    @include medium-up() {
      padding-right: map-get($gutters, 'l');
    }
  }

  &__close-button {
    position: fixed;
    right: map-get($gutters, 's');
    top: map-get($gutters, 's');
  }

  &__list {
    list-style: inside;
  }

  &__final-button {
    width: 142px;
  }
}
