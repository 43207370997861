@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1000px 0;
  }
}

.ImagePlaceholder {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      90deg,
      $gray-1 0%,
      $gray-3 30%,
      $gray-2 60%,
      $gray-1 100%
  );
  background-position: 0 0;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
  display: none;

  &--visible {
    display: block;
  }
}
