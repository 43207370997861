.SiteBuilderAssetIcon {
    background-color: black;
    border-radius: 4px;
    width:40px;
    height:40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    svg {
        @include make-square(24px);
    }
}