.ExternalAccountsSelectableList {
  &__item {
    padding: map-get($gutters, 'xxs') 0;

    &:first-child {
      border-top: 1px solid $gray-2;
      border-bottom: 0;
    }

    border-bottom: 1px solid $gray-2;
  }
}
