$confirmation-page-vertical-spacings: 96px;

.TeamWizard {
  &__submit {
    align-self: flex-start;
    margin: map-get($gutters, '40') auto map-get($gutters, 'l');
    padding: map-get($gutters, 's') map-get($gutters, '40');
  }

  &__texts {
    margin-bottom: $confirmation-page-vertical-spacings;
    text-align: center;
  }

  &__confirmation-cta {
    text-align: center;
    margin-top: $confirmation-page-vertical-spacings;
  }

  &__action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $gray-4;
    padding: map-get($gutters, 'm');
    margin-bottom: map-get($gutters, 'm');
    border-radius: $border-radius-2;

    &--done {
      background-color: $gray-1;
      border-color: $gray-1;
    }

    &-icon {
      @include make-square(24px);

      display: flex;
      align-items: center;
      margin-right: map-get($gutters, 's');
    }
  }
}
