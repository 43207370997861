$spinner-sizes: (
  'extra-small': 0.25rem,
  'small': 1rem,
  'normal': 2rem,
);

$spinner-variants: (
  'primary': $black,
  'inverted': $white,
);

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.Spinner {
  display: none;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;

  &--visible {
    display: inline-block;
  }

  @each $name, $size in $spinner-sizes {
    &--#{$name} {
      @include make-square($size);
    }
  }

  @each $variant, $color in $spinner-variants {
    &--#{$variant} {
      color: $color;
    }
  }
}
