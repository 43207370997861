.Checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: map-get($gutters, 'xs');
  font-size: 18px;
  color: $black;
  align-items: center;
  * {
    box-sizing: border-box;
  }

  &__input {
    display: grid;
    grid-template-areas: 'checkbox';

    > * {
      grid-area: checkbox;
    }
    &__native {
      @include make-square(1em);
      font-size: inherit;
      opacity: 0;
      margin: 0;

      &:checked + .Checkbox__control {
        color: $white;
        background-color: $black;
        border-color: $black;
        svg {
          transform: scale(1);
        }
      }
    }
  }

  &__control {
    @include make-square(1em);
    display: inline-grid;
    line-height: 0;
    border: 0.15em solid $gray-6;

    svg {
      transform: scale(0);
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 400;
  }
}
