.SpaceBadge {
  @include make-square(48px);
  @include makeTypography(normal, 22px, 22px, -0.02em, $white);

  background: $black;
  border-radius: $border-radius-2;
  transition: .05s all ease-in;
  flex-shrink: 0;

  &__avatar {
    max-width: 50px;
    border-radius: $border-radius-2;
  }

  &:hover {
    box-shadow: 0px 0px 0px 4px $gray-6, inset 0px 0px 1px 1px rgba(0, 0, 0, 0.06);
  }

  &--selected {
    box-shadow: 0px 0px 0px 1px $white, 0px 0px 0px 4px $black, inset 0px 0px 1px 1px rgba(0, 0, 0, 0.06);
  }
}
