.ProfileB {
    &-wrapper {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 28px;
      width: 100%;
    }
    &-avatar {
    }
    &-name-bio-wrapper {
      display: flex;
      flex-direction: column-reverse;
      overflow-wrap: break-word;
      z-index: 2;
      text-align: center;
      color: white;
      text-transform: uppercase;
    }
    &-name {
      font-family: 'Playfair Display',serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px;
      margin: 20px auto 0;
      max-width: 340px;
      min-height: 120px;
      width: 100%;
      display: block;
      overflow: hidden;
      }
    &-bio {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 17px;
      margin-top: 24px;
      overflow: hidden;
      text-transform: uppercase;
    }
  }
  