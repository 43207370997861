.SpaceAdder {
  display: flex;
  padding-top: 5px 0;
  align-items: center;
  min-width: max-content;
  cursor: pointer;
  justify-content: flex-end;

  &__icon-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-weight: 800;
  }

  &__icon {
    color: $gray-6;
    border-radius: $border-radius-1;
    padding: map-get($gutters, 'xxs');

    &:hover {

      background-color: $gray-1;
    }
  }
}
