.header-photo-a {
    height: 280px;
    object-fit: cover;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    &-wrapper {
      height: 280px;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    &-blurred {
      display: block;
      filter: blur(8px);
      height: 280px;
      object-fit: cover;
      white-space: nowrap;
      overflow: hidden;

      &-wrapper {
        height: 280px;
        left: 0;
        overflow: hidden;
        position: absolute;
        width: 100%;
      } 
    }
    &-centered-content {
      height: 280px;
      overflow: hidden;
      margin: 0 auto;
      max-width: 991px;
      display: block;
      position: relative;
    }
  }