.BasicDisplayInfoModal {
  width: $basic-display-info-modal-width;

  &__body {
    padding: map-get($gutters, 'l');

    display: flex;

    &__icon {
      color: $red;
      margin-right: map-get($gutters, 'xs');
      margin-top: map-get($gutters, 'xxxs');
    }

    &__title {
      margin-top: 0;
    }

    &__description:last-of-type {
      margin-bottom: 0;
    }
  }
}
