/**
  Alert variants are defined according to this pattern:
  (variantName, backgroundColor, borderColor, fontColor)
 */
$alert-variants: ((error, $red-2, $red, $red),
  (success, $green-2, $green, $green),
  (warning, $yellow, $yellow, $white),
  (info, $blue, $blue, $white)
);

@keyframes alert-slide-in {
  0% {
    top: -100px;
    transform: translate(-50%, 0);
  }

  100% {
    top: 36px;
    transform: translate(-50%, 0);
  }
}

@keyframes alert-slide-out {
  0% {
    top: 36px;
    transform: translate(-50%, 0);
  }

  100% {
    top: -100px;
    transform: translate(-50%, 0);
    display: none;
  }
}

.Alert {
  position: fixed;
  display: flex;
  align-items: center;
  left: 50%;
  top: -100px;
  transform: translate(-50%, 0);
  border-radius: 16px;
  padding: 16px 24px;
  font-size: 15px;
  line-height: 18px;

  &--in,
  &--out {
    animation-duration: .3s;
    animation-timing-function: linear;
  }

  &--in {
    animation-name: alert-slide-in;
    top: 36px;
  }

  &--out {
    animation-name: alert-slide-out;
  }

  &--local {
    position: static;
    transform: translate(0, 0);
  }

  &__close {
    background: transparent;
    border: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 10px;
  }

  @each $variant,
  $backgroundColor,
  $borderColor,
  $fontColor in $alert-variants {
    &--#{$variant} {
      color: $fontColor;
      border: 1px solid $borderColor;
      background-color: $backgroundColor;
    }
  }

  &--full-width {
    display: flex;
    animation: none;
    flex-direction: row;
    justify-content: center;
    transform: none;
    border-radius: 0;
    position: relative;
    left: 0;
    top: -1px;
    box-sizing: border-box;
    width: 100%;

    .Alert__close {
      position: absolute;
      right: 20px;
    }
  }

  &__button {
    margin-left: 5px;
  }
}
