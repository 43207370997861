.SiteBuilderPageLayout {
  &__adder {
    display:flex;
    align-items: center;
  }
    &__header-bar {
        height:46px;
        background-color: $white;
        padding: map-get($gutters, 'xxs');
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      
        &__button {
          justify-content: center;
      
          &-icon {
            border-right: 0 !important;
          }
        }
      }
    &__list {
      padding: 10px 0px;
      &-asset {
        background-color: transparent;
        color: black;
      }
        &-item {
          background: $gray-1;
          margin: map-get($gutters, 'xxs') map-get($gutters, 'xs') map-get($gutters, 'xxs') map-get($gutters, 'xxxs');
          padding: map-get($gutters, 'xxs');
          min-width: fit-content;
          border-radius: $border-radius-4;
          
          &:hover &-content-edits__icon_control {
            visibility: visible;
          }

            &--selected {
              background: $gray-1;
            }
      
            &-icon {
              @include make-square(48px);
              min-width: 48px;
            }
      
            &-content {
              @include makeTypography(normal, 15px, 22px, 1.05px, $black);
      
              display: flex;
              gap: 10px;
              justify-content: space-between;
              
              &-edits {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $black;
                padding: 5px 0;
        
                &__icon_control {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    visibility: hidden;
                    svg {
                      height:32px;
                    }
                }
              }

            }
            &-left-wrapper {
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .drag-icon {
                  color: $gray-6
                }
                .text-content {
                  width: 90px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis
                }
            }

          }
          
    }
    &__add_element {
        @extend .AccountDropdown__button;
        border-top: 0;
        &__icon {
            @extend .AccountDropdown__button-icon;
        
            &-svg {
              @include make-square(38px, '!important');
            }
          }
        
          overflow: hidden;
          padding-left: map-get($gutters, 'xxs');
          text-transform: none;
          font-weight: normal;
        
        
          &--minified {
            border-top: 0 !important;
            width: 44px;
            padding: 0;
          }
        
          &--full {
            margin-top: map-get($gutters, 'xxs');
            padding-top: map-get($gutters, 'l');
            padding-bottom: map-get($gutters, 's');
          }
    }
}