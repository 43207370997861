.UserContainer {
  display: flex;
  background-color: $gray-1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: map-get($gutters, 'xs');
  @include medium-up() {
    padding: map-get($gutters, '40') 0;
  }

  &__content {
    width: 100%;

    @include medium-up() {
      width: 836px;
    }
  }
}
