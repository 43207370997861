.SiteBuilderNewSite {
  width:100%;
  overflow: auto;
  background: black;
  &__wrapper {
    width:90%;
    margin: 0 auto;
    @include small-only() {
      width: 100%;
    }
  }
  &__icons-container {
    text-transform: uppercase;
    p {
      font-size: 12px !important;
    }
  }
    .header {
      color: white;
      padding: 32px;
      margin-top:20px;
      width:445px;
      .product-icon {
        svg {
          @include make-square(63px);
        }
      }
      .right-icon {
        justify-content: normal;
        color: #AAAAAA;
      }
      h1 {
        @include makeTypography(700, 36px, 100%, -0.02em, $white);
      }
      p {
          @include makeTypography(400, 26px, 120%, -0.01em, #AAAAAA);
      }
      .icon {
        justify-content: normal;
        img {
          @include make-square(16px)
        }
        filter: invert(1);
      }
    }
}