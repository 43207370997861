$social-accounts-avatar: '.SocialAccountAvatar';

#{$social-accounts-avatar} {
  position: relative;

  &__image {
    border-radius: 100%;
    border: 2px solid $gray-2;
    opacity: 0.5;
    outline: 1px solid transparent;
    padding: 1px;

    &--placeholder {
      display: flex;
      background-color: $black;
      border-color: $black;
      color: $white;
      text-align: center;
      align-items: center;
      justify-content: center;
      opacity: 1;
      text-transform: uppercase;
    }
  }

  &--selected {
    #{$social-accounts-avatar}__image {
      border: 2px solid $black;
      opacity: 1;
      outline: 1px solid $white;
    }
  }

  &--active {
    #{$social-accounts-avatar}__image {
      opacity: 1;
    }
  }

  &--with-error {
    #{$social-accounts-avatar}__image {
      border-color: $red;
    }
  }

  &__icon {
    @include make-square(16px);

    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid $white;
    border-radius: 100%;
    color: $white;
  }

  &--small {
    #{$social-accounts-avatar}__image {
      @include make-square(28px);
    }
    #{$social-accounts-avatar}__icon {
      @include make-square(12px);
    }
  }

  &--medium {
    #{$social-accounts-avatar}__image {
      @include make-square(38px);
    }
    #{$social-accounts-avatar}__icon {
      @include make-square(14px);
    }
  }
}
