.SiteBuilderNewPage {
    width:100%;
    height:100%;
    background: black;
    .header {
        color: white;
        text-align:center;
        padding: 32px;
        margin-top:20px;
        .product-icon {
          svg {
            @include make-square(63px);
          }
        }
        h1 {
            @include makeTypography(700, 36px, 100%, -0.02em, $white);
        }
        p {
            @include makeTypography(400, 16px, 100%, -0.01em, #AAAAAA);
        }
    }
}