.SiteBuilderContent {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width:100%;
    overflow: hidden;
    
    &__loadable {
        @extend .SiteBuilderContent;
      }
    &__content {
        display: flex;
        flex-direction:row;
        flex-grow:1;
        width: 100%;
        height:100%;
    }
}