.TeamContainer {
  @extend .UserContainer;

  &__content {
    @extend .UserContainer__content;
  }

  &__alert-wrapper {
    text-align: left;
    padding-right: map-get($gutters, 'xxl');
    padding-left: map-get($gutters, 'xxs');

    @include medium-up() {
      text-align: center;
      padding-right: auto;
      padding-left: auto;
    }

  }
}
