$items-spacing: 6px;
$products-bar: '.ProductsBar';

$product-gradients: (
  'design': map-get($gradients, 'products-bar-design-selected-item'),
  'publish': map-get($gradients, 'products-bar-publish-selected-item'),
  'insights': map-get($gradients, 'products-bar-design-selected-item'),
);

#{$products-bar} {
  width: $products-bar-width;
  height: 100%;
  background: black;
  z-index: $zindex-products-sidebar;
  transition: .1s width ease-in-out;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;

  &__placeholder {
    width: $products-bar-width;
    transition: .1s width ease-in-out;

    &--expanded {
      width: $products-bar-expanded-width;
    }
  }

  &--expanded {
    width: $products-bar-expanded-width;
    padding-right: $items-spacing;
  }

  &__handler {
    @include make-square(28px);
    background-color: $black;
    border-radius: 100%;
    border: 3px solid $white;
    box-sizing: border-box;
    color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: -12px;
    z-index: $zindex-icon;
  }

  &__wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__title {
    display: none;
    width: 218px;
    height: 44px;
    color: $white;
    box-sizing: border-box;
  }

  &__logo {
    @include make-square(40px);
    cursor: pointer;
  }

  &__list {
    width: 40px;
    &-item {
      color: $white;
      opacity: 0.5;
      transition: .1s opacity ease-in-out;
      padding-right: map-get($gutters, 'xxs');
      box-sizing: border-box;
      &--selected {
        opacity: 1;
      }
      &:hover {
        background: none;
        opacity: 1;
      }
      &-content {
        display: none;
        margin-left: map-get($gutters, 's');
      }
      &-wrapper {
        display: flex;
        position: relative;
        border: 3px solid transparent;
        border-radius: $border-radius-2;
        background: $black;
        background-clip: padding-box;
        padding: $items-spacing;
        align-items: center;
        margin-left: map-get($gutters, 'xxs');

        &--selected {
          @include make-product-icon('products-bar-design-selected-item');
        }
      }

      &-icon {
        margin-right: 0;
        @include make-square(28px);
        min-width: 28px;
        svg {
          @include make-square(28px);
        }
      }

      @each $product-name, $gradient in $product-gradients {
        &--#{$product-name} {
          #{$products-bar}__list-item-wrapper {
            &--selected {
              &:before {
                background: $gradient !important;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    color: $white;
    padding-right: map-get($gutters, 'xxs');
    box-sizing: border-box;
  }

  &__account {
    border-top: 1px solid $gray-7;
    width: 46px;

    @-moz-document url-prefix() {
      #{$products-bar}__user-badge {
        margin-left: 3px;
      }
    }

    &--expanded {
      width: auto;
    }
  }

  &__help {
    overflow: hidden;
    cursor: pointer;
    margin-left: calc(#{map-get($gutters, 'xm')} - #{map-get($gutters, 'atomic')});
    color: $white;

    &-text {
      padding-left: 0.5px;
    }

    &-text {
      padding-left: 0.5px;
    }

    margin-left: 20px;

    &-wrapper {
      width: $products-bar-expanded-width;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &--expanded {
    #{$products-bar}__title {
      display: block;
    }
    #{$products-bar}__logo {
      display: none;
    }
    #{$products-bar}__list {
      &-item {
        &-wrapper {
          width: 100%;
        }
        &-content {
          display: flex;
        }
      }
    }
  }
}
