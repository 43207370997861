.IconToggler {
  @include make-square(32px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  color: $black;
  border: 0;
  border-radius: $border-radius-1;
  padding: 0;
  margin-left: map-get($gutters, 'm');
  cursor: pointer;

  &--active {
    background: $black;
    color: $white;
  }
}
