$panel-width: clamp(20vw, 300px, 30vw);

.Todos {
  display: flex;
  width: 0;
  box-shadow: inset 1px 0 0 $gray-2;
  background: $white;
  transition: width .150s ease-in-out;

  &--visible {
    width: $panel-width;
  }

  &__wrapper {
    @include make-scrollable-vertically;

    display: flex;
    height: 100%;
    flex-direction: column;
    width: $panel-width;
  }

  &__undo-delete {
    margin-left: map-get($gutters, 'xxs');
  }

  &__todos {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 1px - #{$space-title-bar-height});
  }

  &__title {
    margin: map-get($gutters, 'm');
  }

  &__add-task {
    &-input {
      cursor: pointer;
      width: 100%;
      padding: map-get($gutters, 's') map-get($gutters, 'xxs');
      box-sizing: border-box;
      outline: none;
    }

    &-form {
      padding: map-get($gutters, 's') map-get($gutters, 'm');
    }
  }

  &__completed {
    width: $panel-width;
    overflow: hidden;
    height: 60px;
    bottom: 0;
    padding: 22px 0;
    box-shadow: inset 0 1px 0 $gray-2;
    transition: height .3s ease-in-out;
    box-sizing: border-box;
    margin-top: auto;

    &-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 20px;
      padding: 0 map-get($gutters, 'm');

      &-icon {
        margin-left: auto;
      }
    }

    &--expanded {
      @include make-scrollable-vertically;

      height: fit-content;
    }
  }

  &__list {
    @include make-scrollable-vertically;

    max-height: 300px;
    list-style: none;
    margin: 0;
    padding: 0;

    &--todo {
      max-height: 100%;

      &.Todos__list--narrowed {
        max-height: fit-content;
      }
    }

    &-date {
      color: $gray-6;
    }

    &-title {
      display: flex;
      width: 100%;
      margin-right: map-get($gutters, 's');
      align-items: center;

      &-inner {
        display: flex;
        flex-direction: column;
      }
    }

    &-actions {
      display: none;
      margin-left: auto;
      align-items: center;

      &-button {
        padding: 0;
      }
    }

    &-item {
      display: flex;
      padding: map-get($gutters, 's') map-get($gutters, 'm');
      cursor: pointer;
      transition: background-color .1s ease-in;

      &--completed {
        .Todos__list-title {
          text-decoration: line-through;
        }
      }

      &:hover {
        background-color: $gray-2;

        .Todos__list-actions {
          display: flex;
        }
      }
    }

    &-icon {
      margin-right: map-get($gutters, 's');
      width: 20px;

      &--completed {
        height: 22px;
      }
    }

    &-date {
      color: $gray-4;
    }

    &-input {
      width: fit-content;
      border: 0;
      background: transparent;
      outline: none;
    }
  }

  &__details {
    padding: map-get($gutters, 'm');

    &-delete-icon {
      margin-left: auto;
    }

    &-header {
      display: flex;
    }

    &-input {
      width: calc(#{$panel-width} - #{map-get($gutters, 'xxl')});
    }

    &-datepicker {
      margin: map-get($gutters, 'm') 0;
    }

    &-submit {
      width: 100%;
    }
  }

  &__no-tasks {
    padding: map-get($gutters, 's') map-get($gutters, 'm');
  }
}
