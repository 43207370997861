.ForTeamOnly {
  @include make-square(100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-sizing: border-box;

  &__image {
    margin-bottom: map-get($gutters, '40');
    @include small-only() {
      width: 80%;
    }
  }

  &__text {
    width: 320px;
    color: $gray-6;
    margin-bottom: $margin-2;
  }

  &__account-switcher {
    max-height: 400px;
    &-icon {
      display: none;
    }
  }

  &__loadable {
    @include make-square(100%);
    display: flex;
    flex-direction: row;
  }
}
