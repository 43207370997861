.SocialAccountsCards {
  display: flex;
  flex-direction: row;
  margin-top: map-get($gutters, 'l');

  &__icon {
    margin: auto auto 0 auto;
    color: $white;
  }

  &__button {
    margin-top: auto;
  }

  &__card {
    cursor: pointer;
    padding: map-get($gutters, 's');
    box-sizing: border-box;
    min-height: 222px;
    margin-right: $margin-1;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }
}
