.HandleNotExistView {
    width:100%;
    height:100vh;
    .header {
        color: black;
        text-align:center;
        padding: 32px;
        margin-top:100px;
        .product-icon {
          svg {
            @include make-square(63px);
          }
        }
        h1 {
            @include makeTypography(700, 36px, 100%, -0.02em, $black);
        }
        p {
            @include makeTypography(400, 21px, 100%, -0.01em, black);
        }
    }
}