.SiteBuilderTemplateView {
    width:100%;
    pointer-events : none;
    color: white;
  }
  .SiteBuilderTemplateView_templates {
      align-items: stretch;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .SiteBuilderTemplateView_templates-item{
    display: block;
      width: calc(33% - 2em);
      margin: 0.75em;
      position: relative;
    @media (max-width: 1280px) {
        width: calc(50% - 2em);
    }
    @include small-only() {
        width: calc(100% - 2em);
    }
  }
  .SiteBuilderTemplateView_templates-item-content-wrapper{
    width:90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .SiteBuilderTemplateView_templates-item-content {
    background-color: rgba(170, 170, 170, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
      transition: opacity .4s ease;
    opacity: 0;
    border-radius: 6px;
      cursor: pointer;
      display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  }
  
  .SiteBuilderTemplateView_templates-item-content-a{
    margin: 12px;
    width: 8.5em;
    outline: 0;
    pointer-events: auto;
    font-size: 0.8em;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    height: 3.3em;
    line-height: 3.3em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    letter-spacing: 0.12em;
  }
  
  .SiteBuilderTemplateView_button-inverted {
    background-color: black;
    color: white;
  }
  .SiteBuilderTemplateView_button {
    background-color: white;
    color: black;
  }

  .SiteBuilderTemplateView_templates-item-content:hover {
    opacity:1;
  }
  .SiteBuilderTemplateView_templates-item-a {
        color: rgba(255,255,255,0.75);
      background: rgba(14,15,25,0.175);
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      outline: 0;
      border-bottom: 0;
      position: relative;
  }
  .SiteBuilderTemplateView_templates-item a img{
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 6px;
  }