.AvatarChanger {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 0;

  &__image {
    position: relative;
    z-index: 1;
    max-width: 80px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
    background-image: url('../../../../assets/svg/pencil.svg');
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
    cursor: pointer;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &__modal-content {
    padding: 0;
  }

  &__cropper-wrapper {
    width: 370px;
    height: 370px;
    position: relative;
    display: flex;
  }

  &__modal-dragndrop-indicator {
    width: 302px;
    height: 302px;
    border-radius: 100%;
    border: 2px $gray-4 dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-4;
    text-transform: uppercase;
    font-weight: bold;
    margin: auto;

    &--active {
      color: $black;
      border-color: $black;
    }
  }

  &__upload-progress {
    position: absolute;
    background: rgba($white, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__crop-area {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  &__cropper-controls {
    padding: map-get($gutters, 'l');
    display: flex;
    justify-content: flex-end;
  }

  &__modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  &__modal-update-button {
    margin-left: map-get($gutters, 'm');
  }

  &__modal-upload-button {
    height: 32px;
    background-color: $gray-2;
    border: 0;
    border-radius: 4px;
    color: $black;
    text-transform: uppercase;
    padding-left: map-get($gutters, 's');
    padding-right: map-get($gutters, 's');
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
