.ModalProgressBar {
  &__steps {
    display: flex;
    flex-direction: row;
  }

  &__step {
    width: auto;
    height: 4px;
    background: $black;
  }
}
