.AddInstagramAccountModal {
  width: $add-instagram-account-modal-width;

  &__body {
    padding: map-get($gutters, 'l');
    text-align: center;
    max-height: 600px;
    overflow-y: auto;
  }

  &__card {
    margin-top: $margin-2;
    padding: map-get($gutters, 's');
    border-radius: $border-radius-1;
    box-sizing: border-box;
    min-height: 222px;
    margin-right: $margin-1;
    background-color: $gray-2;
    text-align: left;

    &:last-child {
      margin-right: 0;
    }

    &-list {
      margin-top: 0;

      &-item {
        margin: 6px 0;
      }
    }
  }

  &__info {
    margin-top: $margin-3;
    text-align: left;
  }

  &__accounts-list {
    margin-top: $margin-2;

    &-option {
      margin-top: 0 !important;
    }

    &-item {
      padding: map-get($gutters, 'xxs') 0;

      &:first-child {
        border-top: 1px solid $gray-2;
        border-bottom: 0;
      }

      border-bottom: 1px solid $gray-2;
    }
  }

  &__footer {
    padding: 18px;
  }
}
