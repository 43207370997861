.CaptionEditor {
  border-radius: $border-radius-1;
  box-sizing: border-box;
  border: 1px solid transparent;

  &--focused {
    border: 1px solid $black;
  }

  &__textarea {
    width: 100%;
    min-height: 150px;
    box-sizing: border-box;
    border: none;
    border-radius: $border-radius-1;
    padding: map-get($gutters, 'm');
    resize: none;
    outline: none;
  }

  &__counters {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }

  &__counter {
    padding: 0 map-get($gutters, 'xxs');
    @include remove-edge-paddings;

    &--error {
      color: $red;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $gray-5;
    padding: 0 map-get($gutters, 'm') map-get($gutters, 's');

    &-button {
      color: $gray-5 !important;
      margin: 0;
      padding-left: map-get($gutters, 'xxs');
      padding-right: map-get($gutters, 'xxs');

      @include remove-edge-paddings;
    }
  }
}
