.Input {
  min-height: 66px;

  &__label {
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $gray-5;
    margin-bottom: 4px;

    &-black {
      @extend .Input__label;
      color: black;
    }
  }

  &__message {
    margin-top: 8px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
  }

  &__caption {
    @extend .Input__message;
    padding-bottom: map-get($gutters, 'xxxs');
    color: $gray-5;
  }

  &--error {
    .Input__message {
      color: $red;
    }
    .Input__caption {
      color: $gray-5;
    }
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    padding: 16px 24px 16px 16px;
    border: 1px solid $gray-1;
    background: $gray-1;
    transition: .2s all ease-in-out;
  }

  &__show {
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 0.3;
    cursor: pointer;

    &--active {
      opacity: 1;
    }
  }

  &--outlined {
    .Input__input {
      @include make-button-outlined(transparent, $black);
    }

    &.Input--error {
      .Input__input {
        border-color: $red;
      }
    }
  }

  &:not(.Input--outlined) .Input__input:focus {
    @include make-button-outlined($white, $black);
  }

  &--gray {
    .Input__input {
      @include make-button-outlined($gray-1, $gray-1, '!important');
    }
  }
}

