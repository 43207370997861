$actions-button: '.ActionsButton';

#{$actions-button} {
  display: flex;
  border-radius: $border-radius-1;

  &__button {
    @include make-square(100%);
  }

  &__toggler {
    border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
    padding: 0 map-get($gutters, 'xs');
    height: 100%;
    margin-left: auto;
  }

  &--primary {
    background-color: $black;
    color: $white;

    #{$actions-button}__toggler {
      color: $white;
    }
  }

  &--lg {
    @include large-button;
  }

  &--secondary {
    background-color: $gray-2;
    color: $black;

    .Button__icon {
      border-right: 1px solid $gray-3;
    }
  }

  &--disabled {
    background-color: $gray-3;
    cursor: default;

    #{$actions-button}__toggler {
      @extend #{$actions-button}--disabled;
    }
  }
}
