@mixin make-datepicker-option-selected() {
  background-color: $black !important;
  color: $white !important;
  border: 1px solid $black !important;
}

.react-datepicker {
  padding: map-get($gutters, 'xxxxs');
  border-radius: $border-radius-2 !important;

  &-wrapper {
    width: 100%;
  }

  &__header {
    background-color: $white !important;
    border-bottom: none !important;
  }

  &__day {
    border: 1px solid transparent !important;

    &--selected,
    &--keyboard-selected {
      @include make-datepicker-option-selected;
    }
  }

  &__time-list-item {
    &--selected {
      @include make-datepicker-option-selected;
      padding-bottom: 0 !important;
    }
  }
}

.DatePicker {
  &__button {
    width: 100%;
    background-color: $gray-2;
    color: $black;
    white-space: nowrap;

    &-icon {
      border: none;
      color: $black;
      margin: 0;
    }

    &-placeholder {
      color: $gray-6;
    }
  }
}
