$sides: (all, x, y, top, bottom, left, right);

@each $gutter,
$value in $gutters {
  @each $side in $sides {
    @if $side=='all' {
      .p-#{$gutter} {
        padding: $value !important;
      }
    }

    @else if $side=='x' {
      .p-x-#{$gutter} {
        padding-left: $value !important;
        padding-right: $value !important;
      }
    }

    @else if $side=='y' {
      .p-y-#{$gutter} {
        padding-top: $value !important;
        padding-bottom: $value !important;
      }
    }

    @else {
      $side-prefix: str-slice($side, 0, 1);

      .p-#{$side-prefix}-#{$gutter} {
        padding-#{$side}: $value !important;
      }
    }
  }
}
