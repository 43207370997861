.ManageSocialAccountsModal {
  width: $manage-social-accounts-modal-width;
  overflow: visible;

  &__subtitle {
    margin-top: 0;
  }

  &__body {
    padding: map-get($gutters, 'l');
  }

  &__no-accounts-info {
    margin-top: 0;
    color: $gray-6;
  }

  &__add-account-button {
    margin-top: 8px;
    padding-left: 0;
  }

  &__alert {
    position: static;
    margin: 0 0 map-get($gutters, 'l') 0;
    left: 0;
    top: 0;
    transform: none;
  }

  &__accounts-list {
    @extend .SocialAccountsPicker;
    @extend .SocialAccountsPicker--full;
    &-option {
      @extend .SocialAccountsPicker__option;
    }
    &-item {
      @extend .SocialAccountsPicker__item;
      padding-left: 0 !important;
      padding-bottom: map-get($gutters, 'xxs');
    }
  }
}
