.UserPaywall {

  &__tile {
    .PaywallTile__subtitle {
      height: auto;
    }
  }

  &__confirm-cancellation {
    max-width: 520px;
  }
}
