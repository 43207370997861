.CountWithChartWidget {
  &__heading {
    display: flex;
    align-items: center;
  }

  &__switchers {
    margin-left: auto;
  }

  &__button {
    @include make-square(32px);
    padding: 0;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      margin-right: map-get($gutters, 'xxs');
    }

    &-icon {
      border-right: 0 !important;
      color: $black;

      &--selected {
        color: $white;
      }
    }
  }
}
