.Chip {
  background-color: $gray-2;
  border-radius: $border-radius-1;

  &--transparent {
    background: transparent;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
  }

  &--editable {
    border-radius: 0;

    .Chip__wrapper {
      cursor: pointer;
      &:hover {
        background-color: $gray-1;
      }
    }
  }

  &--editing {
    .Chip__wrapper {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &--info {
    background-color: $blue;
    color: $white;
    text-transform: uppercase;
  }

  &--warning {
    background-color: $yellow;
    color: $white;
    text-transform: uppercase;
  }

  &__wrapper {
    max-width: max-content;
    padding: 5px 6px;
    border-radius: $border-radius-1;

    &--small {
      @extend .small-title;
      padding: 1px 3px;
      color: unset;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    min-height: auto;

    &-message {
      margin-top: 0;
      margin-left: $margin-1;
    }

    &-label {
      display: none;
    }

    &-input {
      cursor: pointer;
      padding: map-get($gutters, 'xxxs');
    }
  }
}
