.EditableInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--editing {
    flex-direction: column;
    align-items: flex-start;

    @include medium-up() {
      flex-direction: row;
      align-items: center;
    }

    .EditableInput__input-wrapper {
      width: 100%;

      @include medium-up() {
        width: auto;
      }
    }

    .EditableInput__actions {
      width: 100%;
      text-align: right;
      margin-bottom: $margin-1;

      @include medium-up() {
        width: auto;
        text-align: left;
      }
    }
  }

  width: 100%;
  border-bottom: 0.5px solid $gray-2;
  justify-content: space-between;

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__input {
    width: 100%;
    height: 21px;
    box-sizing: border-box;
    outline: none;
    background: transparent;
    border: none;
    font-size: 14px;
    color: $black;
    box-sizing: border-box;
  }

  &__cancel-button {
    margin-right: map-get($gutters, 's');
  }
}
