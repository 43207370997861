.SiteBuilderElementEdit {
  padding: map-get($gutters, 'm');
}

.SiteBuilderElementEdit__form {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}


.SiteBuilderElementEditProfile {
  padding: map-get($gutters, 's');
}

  .SiteBuilderEditToolbar {
    margin-top: 10px;

    &__item {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      display: block;
      cursor: pointer;
      border: 0;
  }

    &__horizontal-separator {
      height: 1px;
      background-color: $gray-4;
      margin-top: 20px;
      margin-bottom:50px;
    }

    &__col {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  #font-picker-SiteBuilderEditToolbar {
    width: 150px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important; 
    box-shadow: none !important;

    .dropdown-button {
      background: none;
      border: 1px solid rgb(69, 69, 69);
      border-radius: 9px;
    }

    .dropdown-font-family {
      overflow: hidden;
    }

}