$image-max-height: 60vh;
$slick-controll-buttons-size: 22px;
$slick-controll-buttons-spacing: 20px;
$modal-gutter: 200px;
$max-image-height: calc(100vh - #{$modal-gutter} - #{$modal-header-default-height});

$space-grid-item-preview-modal: '.SpaceGridItemPreviewModal';

#{$space-grid-item-preview-modal} {
  overflow: hidden;

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  &--with-comments {
    display: flex;

    #{$space-grid-item-preview-modal}__content-wrapper {
      width: calc(100% - #{$comments-section-width});
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
  }

  &__header {
    position: relative;
    z-index: 2;
  }

  &__body {
    width: 100%;
    margin: 0 auto !important;
    max-width: 100% !important;
  }

  &__loadable {
    display: flex;
    flex-direction: column;
  }

  &__slider {
    width: 100%;
    max-width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    @include small-only() {
      overflow: hidden;
    }

    .slick-list {
      overflow: visible;

      #{$space-grid-item-preview-modal}__caption {
        max-height: map-get($gutters, '75');
      }
    }

    .slick-next,
    .slick-prev {
      width: $slick-controll-buttons-size;

      &:before {
        font-size: $slick-controll-buttons-size;
      }
    }

    .slick-next {
      right: $slick-controll-buttons-spacing;
    }

    .slick-prev {
      left: $slick-controll-buttons-spacing;
      z-index: 2;
    }

    .slick-dots li {
      margin: 0;
      bottom: -#{map-get($gutters, 'xl')};

      button:before {
        color: $gray-6;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          color: $white;
          opacity: 1;
        }
      }
    }

    &-item {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      width: 100% !important;

      &-image {
        width: auto !important;
        max-width: 100%;
        max-height: $max-image-height;
        margin: 0 auto;
      }
    }
  }

  &__caption {
    @include make-scrollable-vertically;

    width: 90%;
    color: $white;
    max-height: 150px;
    margin: 25px auto 0 auto;
    text-align: center;
  }
}
