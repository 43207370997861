.DropdownButton {
  &__dropdown {
    z-index: $zindex-dropdown;
  }

  &__list-option {
    cursor: pointer;
    background: transparent;
    outline: none;
    padding: 0;
    border: 0;

    &:not(:first-child) {
      margin-top: map-get($gutters, 'm') !important;
    }
  }

  &__item {
    padding: map-get($gutters, 'm') map-get($gutters, 'l');

    &--border-top {
      border-top: 1px solid $gray-2;
    }
  }

  &__dropdown-window {
    left: -86px;
    width: calc(100% + 86px);
    margin-top: 4px;
    z-index: $zindex-dropdown;
  }

  &__icon {
    z-index: $zindex-icon;
  }

  &__trigger {
    padding: map-get($gutters, 'xs') map-get($gutters, 'm');
    display: inline-flex;

    &--secondary {
      @include make-square(32px);
      padding: 0;
      background: $gray-2;
      border-radius: $border-radius-1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
