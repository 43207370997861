$max-chooser-icon-size: 28px;

.PostToChooser {
  &__toggle {
    background-color: $gray-1;
    width: 100%;
    text-align: left;
    border: 0;
    border-radius: $border-radius-1;
    cursor: pointer;
  }

  &__toggle-content {
    display: flex;
    align-items: center;
  }

  &__type-icon {
    width: $max-chooser-icon-size;
    height: $max-chooser-icon-size;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__window {
    width: 100%;
    left: auto;
    margin-top: map-get($gutters, 'xxxs');
    max-height: 400px;
    overflow: auto;
  }

  &__item {
    width: auto;
  }

  &__title {
    font-size: map-get($gutters, 's');
    padding-bottom: map-get($gutters, 'xxxs');
    font-weight: 700;
    text-transform: uppercase;
  }
}
