@mixin make-account-switcher-content-scrollable() {
  @include make-scrollable-vertically;

  max-height: 190px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.AccountDropdown {
  &__separator {
    border-bottom: 1px solid $gray-2;
  }

  &__dropdown {
    display: flex;
    justify-content: center;
    z-index: $zindex-user-badge-dropdown;
  }

  &__accounts-list-dropdown-window {
    margin-top: -129px;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    color: $gray-6;

    &--shrunk {
      justify-content: flex-start;
    }

    .AccountDropdown__toggle {
      width: 100%;
    }
  }

  &__logout {
    cursor: pointer;
    border-radius: $border-radius-2;
  }

  &__toggler {
    display: flex;
    align-items: center;
    width: 234px;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: left;

    &-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: map-get($gutters, 'xxs');
      overflow: hidden;
    }
  }

  &__chevron {
    width: 14px;
    height: 9px;
    margin-left: map-get($gutters, 's');
    padding-left: map-get($gutters, 'xxxs');
    box-sizing: border-box;
  }

  &__icon-sizing {
    @include make-square(18px)
  }

  &__button {
    margin: 0;
    padding: 0;
    width: 100%;
    text-transform: none;
    text-align: left;
    font-weight: normal;
    letter-spacing: 0;
    color: $gray-6;

    &-icon {
      border-right: none;
      margin-right: 0;

      svg {
        @include make-square(32px);
      }
    }
  }

  &__modal-body {
    box-sizing: border-box;
    min-width: 300px;
    padding: map-get($gutters, 'l');
  }

  &__window {
    color: $black;
    left: 0;
    width: 280px;
  }

  &__selectable-accounts-list {
    @include make-account-switcher-content-scrollable;
    @include medium-up() {
      max-height: 240px;
    }
  }

  &__option {
    border-bottom: 1px solid $gray-3;
    margin-bottom: 0 !important;

    &:first-child {
      border-top: 1px solid $gray-3;
    }
    &:last-child {
      border-bottom: none;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__dropdown-content {
    padding: 0;
  }

  &__create-team-modal {
    width: 327px;

    &-icon {
      @include make-square(16px);
      margin-right: 8px;
    }

    &-body {
      padding: 0 map-get($gutters, 'l');
    }
  }
}
