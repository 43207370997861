.SiteBuilderLandingContainer {
    display: flex;
    flex-direction:column;
    align-items: center;
    margin: 0 36px;
    width:100%;
    text-align:center;
    overflow: auto;
    z-index:1;

    &__submit {
        cursor: pointer;

        background-color: black;
        color:white;
        padding:2px;
        border-radius: 50%;
        svg {
            display:block;
        }
    }
    &__background {
        position: fixed;
        width:100%;
        height:100%;
        top: 0;
        left:0;
        background-color: black;
        .background-cover {
            height: 100%;
            width: 100%;
            background: linear-gradient(149deg, rgba(0,0,0,1) 0%, rgba(9,9,121,1) 92%, rgba(53,0,168,0.7287289915966386) 98%);

        }
        video {
            height: 100%;
            width: 100%;
            opacity:0.2;
            object-fit: cover;
            background-position: center;
        }
    }
    &__title {
        margin-top: 50px;
        h1 {
            font-size: 64px;
            line-height: 1em;
            font-weight: 700;
            letter-spacing: -.02em;
        }
    }
    &__input-wrapper {
        border: 3px solid rgba(5, 4, 4, 0.752);
        border-radius: 50px;
        padding: 11px 50px 11px 50px;
        cursor: text;
        max-width: 450px;
        margin:0 auto;
        display: flex;
        align-items: center;
        font-size: 22px;
        justify-content: space-between;
    }

    &__domain {
        font-size: 17px;
        color: #AAAAAA
    }

    &__input {
      border: none;
      background: none;
      outline: none;
      padding: 0;
      color: #1a1a1a;
      margin: 0;
      font-size: 17px;
    }
    &__content-item {
        text-align:left;
        flex-grow:1;
        flex-basis: 0;
      }
    &__input-error {
        display:block;
        color:red;
    }
    &__input-hide-error {
        display:none;
    }

}