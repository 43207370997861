.LinkA {
  &__title {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  &__description {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.link-a {

    margin: 0px auto;
    color: rgb(0, 0, 0);
    max-width: 485px;
    height: auto;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
    text-align: center;
    cursor: pointer;
    background: none;
    text-decoration: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    width: 90%;
    box-sizing: border-box;
    vertical-align: middle;
    border-bottom: 1px solid black;
    padding: 10px 28px 10px 0;

    &-wrapper{
      margin: 0;
      padding: 0px;
      height: 100%;
      width: 100%;
      vertical-align: baseline;
      overflow: hidden;
      margin-bottom: 16px;
    }
      &-text {
        padding: 5px;
        margin: 0px;
        line-height: 1.5;
        width: 100%;
        border: 0px;
        vertical-align: baseline;
        display:flex;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .5px;
        line-height: 16px;
        text-transform: uppercase;
    }

    &-number {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      overflow: hidden;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 4px;
        display: flex;
        right: 4px;
      width: 46px;
      height: 46px;
    }
  }
