.PostTimeDropdownFieldToggle {
    @include makeTypography(bold, 13px, 13px, 0.07em, $white);
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $black;
    text-transform: uppercase;
    text-align: center;
    padding: map-get($gutters, 'xs') map-get($gutters, 'm');
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    width: 100%;
    background-color: $gray-2;
    color: $black;

    &--window {
      left: -46px;
      width: calc(100% + 46px);
    }
}
