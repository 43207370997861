$margin: map-get($gutters, 'l');
$row-height: 116px;

.CountCompoundWidget {
  &__first-row {
    text-align: center;
    margin-top: $margin;
    height: $row-height;
  }

  &__second-row {
    display: flex;
    justify-content: space-evenly;
    margin-top: $margin;
    overflow-x: auto;
    overflow-y: hidden;
    height: $row-height;
  }
}
