.CloneSpaceModal {
  width: $rename-space-modal-width;

  &__heading {
    margin-top: 0;
  }

  &__footer {
    display: flex;

    .ButtonGroup {
      margin-left: auto;
    }
  }

  &__upload-warning {
    width: 300px;
    color: $yellow;
    display: flex;
    align-items: center;
    margin-right: map-get($gutters, 's');

    &-icon {
      width: 48px;
      margin-right: map-get($gutters, 'xxs');
    }
  }
}
