.InviteTeamMembers {
  &__modal-no-transform {
    transform: translateY(0);
  }

  &__toggle {
    width: 100%;
    font-weight: normal;

    @include medium-up() {
      width: initial;
      font-weight: bold;
    }
  }

  &__invite {
    width: 100%;
    padding: map-get($gutters, 'm') map-get($gutters, 'l') 50px;
    box-sizing: border-box;
    max-height: 450px;
    overflow: scroll;

    @include medium-up() {
      width: 655px;
      padding: map-get($gutters, 'm') map-get($gutters, 'l') 70px;
    }

    &-title {
      margin: 0 0 $margin-5;
    }

    &-field {
      margin-bottom: map-get($gutters, 'l');
    }
  }
}
