.PaymentSuccessModal {
  &__body {
    padding: map-get($gutters, 'l');

    @include medium-up() {
      width: 566px;
    }
  }

  &__footer {
    text-align: right;
  }
}
