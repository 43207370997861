.QuoteCard {
    display: flex;
    gap: map-get($gutters,'s');
    flex-direction: column;
    background: $gray-7;
    padding: map-get($gutters,'m');
    margin: map-get($gutters,'m');
    border-radius: 8px;

    &__quote {
        @include makeTypography(200, 15px, 120%, -0.01em, $white);
    }
    &__user{
        display: flex;
        flex-direction: row;
        gap: map-get($gutters,'s');
        align-items: center;

        &-title {
            @include makeTypography(700, 13px, 100%, -0.02em, $white);
        }
        &-description {
            padding-top: map-get($gutters,'xxxs');
            @include makeTypography(400, 11px, 120%, -0.01em, $gray-5);
        }

        &-img {
            border-radius: 50%;
            overflow: hidden;
            width: 48px;
            height: 48px;
            object-fit: cover;
            img{
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
            }
        }
    }
}