.Table {
  width: 100%;
  border-spacing: 0;

  &--scrollable {
    width: max-content;

    @include medium-up() {
      width: 100%;
    }

    @include small-only() {
      .Table__column-inner {
        padding-right: map-get($gutters, 'l');
      }
    }
  }

  &__scrollable-arena {
    @include make-scrollable-horizontally();
  }

  &__head {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $gray-5;

    &-column {
      border-bottom: 0.5px solid $gray-4;
      padding-bottom: map-get($gutters, 'm');

      &:first-child {
        padding-left: map-get($gutters, 'm');
        /* @TODO: Check if padding 24px is good for us */
      }
    }
  }

  &__row {
    padding-top: map-get($gutters, 's');
    padding-bottom: map-get($gutters, 's');
  }

  &__column {
    vertical-align: center;
    padding: map-get($gutters, 's') 0;
    align-items: center;
    border-bottom: 0.5px solid $gray-4;

    &:first-child {
      padding-left: map-get($gutters, 'm');
      /* @TODO: Check if padding 24px is good for us */
    }

    &-inner {
      display: flex;
      width: 100%;
    }
  }
}
