.SiteBuilderView {
    width:100%;
    display:flex;
    flex-direction: column;
    position:relative;

    &__nav {
        width: 100%;
        height:48px;
        margin-top: 16px;
        padding: 0 map-get($gutters, 's');
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

}