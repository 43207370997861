.DateRangePicker {
  &__content {
    display: flex;
  }

  &__window {
    width: auto;
    left: 0;
    top: $margin-1;
  }

  &__footer {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  &__calendar {
    border: 0;

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background-color: $gray-1;
      color: $black;
    }

    .react-datepicker__day--range-end {
      background-color: $black;
      color: $white;
    }

    * {
      font-family: unset;
    }
  }

  &__toggle {
    background-color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: map-get($gutters, 'xxs');
  }
}
