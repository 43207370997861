.SiteBuilderPageLayoutContainer {
    width:500px;
    background-color: white;
    padding: 8px 15px;
    overflow-y: auto;
    overflow-x: hidden;
    &__nav {
        width: 100%;
        margin-top: 10px;
        height: 54px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F5F5F5;
        position:relative;
        &-button {
            cursor: pointer;
            position:absolute;
            height: 100%;
            left:0;
            display: flex;
            align-items: center;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
} 