.ReadOnlyInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid $gray-2;
  justify-content: space-between;

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__input {
    width: 100%;
    height: 21px;
    box-sizing: border-box;
    outline: none;
    background: transparent;
    border: none;
    font-size: 14px;
    color: $black;
    box-sizing: border-box;
    padding-left: 0;
  }
}
