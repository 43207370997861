.SiteBuilderThemeEdit{

    display: flex;
    flex-direction: column;
    &__content {
        padding-top: map-get($gutters, "l" )
    }
    &__label {
        @extend .Input__label;
        color:black;
        font-weight: 700;
    }
    &__description {
        font-size: 14px;
        color: $gray-6;
    }

    &__editor {
        width:100%;
        height:0px;
        transition: height 0.1s ease-in-out;
    }
    &__button {
        background-color: white;

        &-icon {
            border-right: none !important;
        }
    }
    .expand {
        height:auto;
    }

    #font-picker-SiteBuilderThemeEdit {
        width: 100% !important;
        height:48px !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important; 
        box-shadow: none !important;
    
        .dropdown-button {
        //   background: none;
          border-radius: 9px;
          height:100%;
          background-color: $gray-input;
        }
    
        .dropdown-font-family {
          overflow: hidden;
        }
    
    }


}
