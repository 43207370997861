.SiteLifetimeAnalytics {
    &__right {
        padding: 0 10px;
    }
    &__analytics {
        display: flex;
        align-items: center;
    }
    &__header {
        padding: 0 22px;
        height: 64px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &-content {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }
    &__chart {
        width: 100%;
        background-color: white;
        position: absolute;
        z-index: 2000;
        transition: max-height 0.15s ease-in-out;
    }
    
    &__indicator {
        height: 6px;
        width: 6px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 2px;
    }

    .red {
        background-color: red
    }
    .green {
        background-color: green
    }

    .hide {
        max-height: 0;
    }
    .expand {
        max-height: 200px;
    }
}