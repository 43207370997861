.StoryboardPreviewContainer {
  width: 100%;
  padding: map-get($gutters, 'm');
  box-sizing: border-box;

  @include medium-up() {
    width: 840px;
    padding: map-get($gutters, '90') 0;
  }

  @include large() {
    width: 1136px;
  }

  margin: 0 auto;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include medium-up() {
      flex-direction: row;
    }
  }

  &__loadable {
    height: calc(100vh - #{map-get($gutters, 'xl')});
  }

  &__photos {
    @include make-scrollable-vertically;

    width: 100%;
    margin-right: $margin-4;
    max-height: 40vh;

    @include medium-up() {
      width: 50%;
      max-height: 50vh;
    }
  }

  &__details {
    width: 100%;
    @include medium-up() {
      width: 50%;
    }
  }

  &__close {
    text-align: right;
    margin-bottom: $margin-4;

    &-button {
      @include make-square(36px);

      padding: 0;
      background-color: rgba($black, 0.8);
      border-radius: 100%;
    }
  }

  &__subtitle {
    color: $gray-6;
  }

  &__section {
    h4 {
      font-weight: bold;
      color: $gray-5;
      text-transform: uppercase;
    }
    padding-bottom: map-get($gutters, 'l');
    border-bottom: 1px solid $gray-2;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__tags-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    display: inline;
    padding: map-get($gutters, 'xxs');
    border-radius: $border-radius-1;
    margin-right: map-get($gutters, 'xxs');
    margin-top: map-get($gutters, 'xxxs');
    margin-bottom: map-get($gutters, 'xxxs');

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;

    @include medium-up() {
      flex-direction: row;
    }
  }

  &__action-button {
    width: 50%;

    @include medium-up() {
      width: auto;
    }

    &:first-of-type {
      margin-left: $margin-1;
      @include medium-up() {
        margin-left: 0;
        margin-right: $margin-1;
      }
    }
  }

  &__credits {
    display: flex;
    align-items: center;
    color: $black;

    &-author {
      letter-spacing: normal;
    }

    .UserBadge {
      margin-right: map-get($gutters, 'xxs');
    }
  }
}
