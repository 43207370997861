.ImageA {
    &__link {
        width:100%;
    }
    &__title {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    &__description {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}