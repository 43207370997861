.ScheduledPostsList {
  margin: 0 auto;
  width: max-content;
  max-width: 100%;
  padding-bottom: 80px;

  &__group-title {
    display: inline-flex;
    align-items: baseline;
  }

  &__Loadable {
    height: 100%;

    @include medium-up() {
      height: calc(100% - 100px);
    }

    &:after {
      background-color: $gray-1 !important;
    }
  }
}
