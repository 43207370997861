.TeamMembers {
  @extend .ProfileInformation;

  @include medium-up() {
    padding: map-get($gutters, 'xl') 50px 50px;
  }

  flex-direction: column;

  &>* {
    margin-bottom: map-get($gutters, "l")
  }

  &__invite-cta {
    width: fit-content;
  }

  &__table {
    margin-top: $margin-4;
  }

  &__user-badge {
    margin-right: $margin-1;
  }
}
