.AccountDropdownHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: map-get($gutters, 's');

  &__text {
    text-align: left;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;

    &-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__avatar {
    @include make-square(40px);
    border-radius: 50%;
  }

  &__chevron {
    margin-left: auto;
  }
}
