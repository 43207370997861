.AddSocialAccountModal {
  min-width: $add-social-account-modal-width;

  &__subtitle {
    margin-top: 0;
  }

  &__body {
    padding: map-get($gutters, 'l');
    text-align: left;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    margin-top: map-get($gutters, 'l');
  }

  &__icon {
    margin: auto auto 0 auto;
  }

  &__button {
    margin-top: auto;
  }

  &__card {
    cursor: pointer;
    padding: map-get($gutters, 's');
    box-sizing: border-box;
    min-height: 222px;
    margin-right: $margin-1;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }
}
