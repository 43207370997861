.ExpiredTeamContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__content {
    width: 320px;
    text-align: center;
  }

  &__text {
    color: $gray-6;
    margin-bottom: 0;
  }

  &__cta {
    margin-top: map-get($gutters, 'l');
  }

  &__alert {
    position: absolute;
  }
}
