@include with-side-photo-container('.StudentPricingContainer', '../../assets/images/student-pricing-bg.png') {
  .StudentPricingContainer {
    &__price {
      font-size: 44px;
      font-weight: bold;

      &--small {
        font-size: 15px;
        font-weight: normal;
      }

      &--regular {
        color: $gray-5;
        text-decoration: line-through;
      }
    }

    &__perk {
      margin: 0;
      padding: 0;
    }
  }
};
