$imageSize: 160px;

.ImportAssetModal {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    .SchedulePostMediaAdder {
        margin-top: 3px;
        @include make-square($imageSize);

        &__image {
            @include make-square($imageSize);
        }

        &__error {
            width: $imageSize;
        }
    }
}