.BestTimeToPostWidget {
  height: 100%;

  &__body {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: map-get($gutters, 'l');
    height: calc(100% - 2 * #{map-get($gutters, 'l')});
  }

  &__map {
    width: 50%;
    padding-right: map-get($gutters, 'l');
  }

  &__bar-chart {
    width: 50%;
    padding-left: map-get($gutters, 'l');
  }

  &__chart-axes {
    margin-top: 0;
    font-size: 12px;
    font-weight: bold;
  }
}
