$space-dropdown-width: 252px;

.PageActionDropdown__dropdown-window {
  @extend .DropdownButton__dropdown-window;
  left: -$space-dropdown-width;
  width: calc(100% + #{$space-dropdown-width});
  border: 1px solid $gray-2;
  border-radius: $border-radius-2;

  .Dropdown__content {
    padding: 0;
  }

  .SelectableListItem {
    &__description {
      margin-left: $margin-1;
      width: 100%;
    }
  }
}
