.ScheduledPost {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $gray-2;
  border-radius: $border-radius-1;
  width: 100%;

  @include medium-up() {
    width: 480px;
  }

  &--highlighted {
    animation-name: highlight-element;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }

  &__social-accounts {
    padding: map-get($gutters, 's') map-get($gutters, 's') 0 map-get($gutters, 's');
    display: flex;
  }

  &__body {
    padding: map-get($gutters, 'm') 0 0 map-get($gutters, 's');
    display: flex;
  }

  &__caption {
    padding: 0 map-get($gutters, 's') map-get($gutters, 'm') 0;
    max-height: 300px;
    overflow: auto;
  }

  &__errors {
    padding: 0 map-get($gutters, 's') map-get($gutters, 's');
  }

  &__info {
    padding: 0 map-get($gutters, 's') map-get($gutters, 's');
  }

  &__auto-post {
    display: inline-flex;
    align-items: center;
  }

  &__dropdown-trigger {
    @include small-only {
      height: 45px;
    }
  }

  &__image {
    @include make-square(100px);
    object-fit: cover;
    margin-bottom: map-get($gutters, 'm');
  }

  &__footer {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray-2;
    padding: map-get($gutters, 's');

    &__actions {
      display: flex;
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__play-icon {
    position: absolute;
    top: map-get($gutters, 'xxs');
    left: map-get($gutters, 'xxs');
    color: $black;

    &--dark {
      color: $white;
    }
  }

  &__origin-indicator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;

    &-wrapper {
      position: relative;
      z-index: 0;
      cursor: pointer;
      border-radius: $border-radius-1;
      transition: .1s background-color ease-in;
      &:hover {
        background-color: $gray-2;
      }
    }

    &-badge {
      display: flex;
      align-items: center;
    }

    &-icon {
      @include make-product-icon('products-bar-design-selected-item');
      @include make-square(24px);

      display: flex;
      position: relative;
      border: 3px solid transparent;
      border-radius: $border-radius-2;
      background: $black;
      background-clip: padding-box;
      align-items: center;
      color: $white;

      &:after {
        display: none;
      }
    }
  }
}
