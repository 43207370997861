.Tooltip {
  @include makeTypography(normal, 13px, 16px, -0.01em, $black);

  padding: 6px map-get($gutters, 'xxs') !important;

  &--light {
    background: $white !important;
    border: 1px solid rgba($black, 0.06) !important;
    box-shadow: 0px 2px 4px -2px rgba($black, 0.06) !important;
    border-radius: $border-radius-1 !important;
  }

  &:after {
    display: none;
  }
}
