.SiteBuilderElementAdder {

    &__nav {
      padding: map-get($gutters, "m" );

    }

    &__list {
        &-item {
            margin: map-get($gutters, 'xxs') map-get($gutters, 'xs') map-get($gutters, 'xxs') map-get($gutters, 'xxxs');
            padding: map-get($gutters, 'xxs');
            border-radius: $border-radius-4;
            min-width: fit-content;
            cursor: pointer;

            &:hover,
            &--selected {
              background: $gray-1;
            }
      
            &-icon {
              @include make-square(48px);
              min-width: 48px;
            }
      
            &-content {
              @include makeTypography(normal, 15px, 22px, 1.05px, $black);
      
              display: block;
              margin-left: map-get($gutters, 'xxxs');
            }
            &-left-wrapper {
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
            &-content-edits {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: black;

                .text-content {
                  .title {
                    font-weight: 600;
                    margin: map-get($gutters, 'xxxs') 0;
                  }
                }

                &__icon_control {
                    display: flex;
                    gap: 8px;
                }

              }
          }
          
    }
    &__add_element {
        @extend .AccountDropdown__button;
        border-top: 0;
        &__icon {
            @extend .AccountDropdown__button-icon;
        
            &-svg {
              @include make-square(38px, '!important');
            }
          }
        
          overflow: hidden;
          padding-left: map-get($gutters, 'xxs');
          text-transform: none;
          font-weight: normal;
        
        
          &--minified {
            border-top: 0 !important;
            width: 44px;
            padding: 0;
          }
        
          &--full {
            margin-top: map-get($gutters, 'xxs');
            padding-top: map-get($gutters, 'l');
            padding-bottom: map-get($gutters, 's');
          }
    }
}