$padding-l: map-get($gutters, 'l');

.ConfirmationModal {
  width: $confirmation-modal-max-width;

  &__heading {
    margin-top: 0;
  }

  &__body {
    padding: $padding-l $padding-l 0 $padding-l;
  }

  &__footer {
    @extend .ConfirmationModal__body;
    padding-bottom: $padding-l;
  }
}
