$gridSizes: 1, 2, 3, 4;

.HashtagsWidget {
  background: $white;
  display: flex;
  flex-direction: column;

  &__wrapper {
    text-align: center;
    display: grid;
    width: 90%;
    margin: map-get($gutters, 'l') auto 0 auto;

    @each $gridSize in $gridSizes {
      &-grid-#{$gridSize} {
        grid-template-columns: repeat($gridSize, minmax(50px, 1200px));
      }
    }
  }
}
