$button: '.Button';

@mixin large-button() {
  height: 48px;
  font-size: 15px;
  padding: 12px 15px;
}

@mixin large-button-with-icon($icon-src) {
  @include large-button;

  &:before {
    content: '';
    width: 21.25px;
    height: 21.25px;
    margin-right: 12px;
    background: url($icon-src) no-repeat;
  }
}

#{$button} {
  @include makeTypography(bold, 13px, 13px, 0.07em, $white);
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $black;
  text-transform: uppercase;
  text-align: center;
  padding: 9.5px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  justify-content: center;

  &[disabled] {
    background-color: $gray-3;
    cursor: default;
  }

  &--active {
    background: red;
  }

  &--pullRight {
    margin-left: auto;
  }

  &--fullWidth {
    width: 100%;
  }

  &--primary {
    background-color: $black;
    color: $white;
  }

  &--secondary {
    background-color: $gray-2;
    color: $black;

    .Button__icon {
      border-right: 1px solid $gray-3;
    }
  }

  &--selected {
    opacity: 1 !important;
    border-bottom: 4px solid $white !important;
  }

  &--danger {
    background-color: $red;
    color: $white;
  }

  &--transparent {
    background: transparent;
    color: $black;

    &[disabled] {
      background: transparent;
      color: $gray-3;
      cursor: default;
    }
  }

  &--badge {
    border-radius: 24px;
    padding: map-get($gutters, 'xxs') map-get($gutters, 'm');
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
  }

  &--facebook {
    @include large-button-with-icon('../../assets/svg/fb.svg');
    background-color: $fb-blue;
    color: $white;
  }

  &--apple {
    @include large-button-with-icon('../../assets/svg/apple.svg');
    background-color: $black;
    color: $white;
  }

  &--snapchat {
    @include large-button-with-icon('../../assets/svg/snapchat.svg');

    background-color: $snapchat-yellow;
    color: $black;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--right {
      flex-direction: row-reverse;
    }
  }

  &__icon {
    margin-right: map-get($gutters, 's');
    border-right: 1px solid $gray-7;
    
    &--left {
      padding-right: map-get($gutters, 's');
    }

    &--right {
      padding-left: map-get($gutters, 's');
    }

    &--center {
      margin:0;
      border:0;
    }
  }

  &--link {
    background: none;
    border: none;
    text-decoration: underline;
    letter-spacing: normal;
    padding: 0;
  }

  &--circle {
    border-radius: 100%;
  }

  &--lg {
    @include large-button;
  }

  &--sm {
    height: 32px;
    padding-left: map-get($gutters, 's');
    padding-right: map-get($gutters, 's');
  }
}
