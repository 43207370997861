.PaywallTilePrice {
  margin-top: map-get($gutters, 'm');
  min-height: 47px; // fix for constant height 

  &__amount {
    font-size: 26px;
    line-height: 31px;
  }

  &__period {
    font-size: 15px;
  }

  &__details {
    color: $black;
  }
}
