@include with-side-photo-container('.SignupContainer', '../../assets/images/signup-bg.png') {
  .SignupContainer__terms {
    font-size: 15px;
  }
  .SignupContainer {
    &--success {
      .SignupContainer {
        &__wrapper {
          width: 100%;
          height: calc(100vh - #{$signup-topbar-height});
        }
        &__confirmation-copy {
          font-size: 17px;
          line-height: 20px;
          letter-spacing: -0.01em;
        }
        &__start-designing-button {
          padding: 16.5px 46px;
        }
      }
    }
  }
}
