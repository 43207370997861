.BarChart {
  width: 100%;
  box-sizing: border-box;
  padding: map-get($gutters, 'xxs');

  &__row {
    display: flex;
    flex-direction: row;
    margin: map-get($gutters, 'xs') 0;
  }

  &__axes {
    @extend .BarChart__row;
    border-bottom: 1px solid $gray-2;
    padding-bottom: map-get($gutters, 'xxs');
  }

  &__progress-bar {
    width: 100%;
    display: flex;
  }

  &__axis-x,
  &__axis-y {
    display: flex;
    align-items: flex-end;
    width: 50%;
    color: $gray-5;
    text-transform: uppercase;
  }

  &__axis-y {
    justify-content: flex-end;
    text-align: right;
  }

  &__progress {
    width: 100%;
    min-width: 0;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: map-get($gutters, 'xxs');
    text-align: right;
    color: $black;
    background-color: $gray-3;
    cursor: pointer;

    &-wrapper {
      display: block;
    }

    &--highlighted {
      color: $white;
      background-color: $purple-1;
    }
  }

  &__value {
    min-width: 58px;
    margin-left: auto;
    text-align: right;
  }
}
