$border: 1px solid $gray-2;
$convert-ig-account-modal: '.ConvertIGAccountModal';

#{$convert-ig-account-modal} {
  display: flex;
  flex-direction: column;

  &__body {
    padding: map-get($gutters, 'm');
    flex: 1;
    text-align: center;
    margin: auto;
    overflow: auto;

    &__title {
      width: 375px;
      margin-left: auto;
      margin-right: auto;
    }

    &__checklist {
      border-top: $border;
      margin-top: map-get($gutters, 'xl');
    }

    &__help-section {
      text-align: left;
      margin-top: map-get($gutters, 'xl');

      &__link {
        color: $blue;
        margin-top: map-get($gutters, 'xxxs');
      }
    }
  }

  &__checklist-option {
    padding: map-get($gutters, 'm') map-get($gutters, 'xxs');
    border-bottom: $border;

    &--selected {
      background: $green-3;
      border-bottom-color: $green-3;

      #{$convert-ig-account-modal}__checklist-option__description__title {
        color: $green;
      }
    }
  }
}
