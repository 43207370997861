.MembershipContainer {
    width: 100%;
    height: 100%;
    background: black;
    padding-bottom: 150px;
    &__feature-container {
        display: flex;
        gap: 20px;
        overflow: auto;
        flex-wrap: wrap;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .header {
        color: white;
        text-align:center;
        padding: 32px;
        margin-top:20px;
        .product-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            row-gap:20px;
            column-gap:10px;
          svg {
            @include make-square(63px);
          }
        }
        h1 {
            @include makeTypography(700, 36px, 100%, -0.02em, $white);
        }
        p {
            @include makeTypography(400, 16px, 100%, -0.01em, #AAAAAA);
        }
    }
}

.Featured{
    &-featured-img {
        display: block;
        margin: map-get($gutters,'xl') auto;
    }
}

.FixedButtonContainer {
    position: fixed;
    width:100%;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.StartTrialButton {
    width: 90%;
    border-radius: 24px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 29px 12px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 29px 12px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 29px 12px rgba(0,0,0,0.75);
}