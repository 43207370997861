.TogglerRow {
  &__outerLabel {
    @extend .Input__label;

    margin-bottom: 8px;
  }

  &__bar {
    display: flex;
    border-radius: 2px;
    background: $gray-1;
    align-items: center;
    line-height: 0;
    padding: 14px map-get($gutters, 'm');
  }

  &__label {
    margin-left: #{"min(10px, 4vw)"};
    @include medium-up() {
      margin-left: #{"min(#{$margin-1}, 4vw)"};
    }
  }

  &__toggler {
    width: #{"min(48px, 10vw)"};
    @include medium-up() {
      width: #{"min(32px, 10vw)"};
    }
  }
}
