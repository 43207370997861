.StoryboardPreviewGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    margin: 0.5px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: .2s all ease-in-out;
  }
}

