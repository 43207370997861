.DashboardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__loadable {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$space-title-bar-height});
    position: relative;
  }

  &__loader {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
  }
}
