.SurveyAfterCancellationModal {
  text-align: center;

  &__body {
    @include medium-up() {
      max-width: $confirmation-modal-max-width !important;
      margin: 0 auto;
    }
  }

  &__explanation {
    color: $gray-6;
  }
}
