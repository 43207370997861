$image-size: 100px;
$image-button-padding: 6px;

.SchedulePostMediaAdder {
  @include make-square($image-size);
  display: flex;
  border: 1px dashed $gray-3;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $gray-6;
  cursor: pointer;
  position: relative;
  margin-top: map-get($gutters, 's');

  &:hover {
    border-color: $black;
    color: $black;
  }

  &--error {
    border: 1px dashed $red;
    opacity: 0.8;
  }

  &--no-border {
    border: none;
  }

  &__image {
    @include make-square($image-size);
    object-fit: cover;

    &.warning {
      border: 2px solid $red;
      opacity: 0.8;
    }

    &--error {
      border: 1px dashed $red;
      opacity: 0.8;
    }
  }

  &__error {
    width: $image-size;
    font-size: 12px;
    color: $red;
    font-weight: 400;
  }

  &__remove-button,
  &__edit-button,
  &__play-icon,
  &__expand-button {
    position: absolute;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    top: $image-button-padding;
    right: $image-button-padding;
    color: $black;
    @include make-square(13px);

    &--dark {
      color: $white;
    }
  }

  &__edit-button-circle,
  &__remove-button-circle,
  &__expand-button-circle {
    @include make-square(auto);
    color: $white;
    background-color: $black;
    display: flex;
    align-items: center;
    border-radius: map-get($gutters, 'm');
    padding: map-get($gutters, 'xxs');
  }

  &__edit-button-circle {
    left: $image-button-padding;
    padding: map-get($gutters, 'xxxs') map-get($gutters, 'm');
    font-size: 14px;
    &__icon {
      margin-right: map-get($gutters, 'xxxs');
    }
  }

  &__expand-button {
    @include make-square(28px);
    top: auto;
    bottom: 12px;
    right: $image-button-padding;
  }

  &__remove-icon,
  &__edit-icon,
  &__play-icon {
    @include make-square(13px);
  }

  &__edit-button,
  &__play-icon {
    left: $image-button-padding;
  }
}
