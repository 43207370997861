.SiteBuilderViewDeviceSwitch {
    &__content {
        display:flex;
        flex-direction: row;
    }

    &-icon {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        margin-left: 16px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
            @include make-square(28px);
        }
        &--primary {
            background-color: $black;
            color: $white;
        }
        &--secondary {
            background-color: $gray-2;
            color: $black;
        }
    }
}