.WithSupportedAccounts {
    @include make-square(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-sizing: border-box;

    &__text {
        width: 330px;
        color: $gray-6;
        margin-bottom: $margin-2;
    }

    &__loadable {
        @include make-square(100%);
    }
}
