.ProgressBar {
  background-color: $white;
  height: 8px;
  border-radius: 4px;
  width: 100%;

  &__filler {
    height: 100%;
    border-radius: inherit;
    background-color: $black;
    transition: width 1s ease-in-out;
  }
}
