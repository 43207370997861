.SelectableList {
  display: flex;
  flex-direction: column;

  &__option {
    cursor: pointer;
    background: transparent;
    outline: none;
    border: 0;
    padding: 0;
    min-height: fit-content;

    &:not(:first-child) {
      margin-top: map-get($gutters, 'm');
    }
  }
}
