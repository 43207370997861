.TeamMembership {
  @extend .ProfileInformation;

  @include medium-up() {
    padding: map-get($gutters, 'xl') 50px 50px;
  }

  flex-direction: column;

  &>* {
    margin-bottom: map-get($gutters, "l")
  }

  &__trial-info {
    @include makeTypography(normal, 13px, 16px, -0.01em, $gray-6);
  }

  &__readonly-input {
    margin: $margin-1 0;

    &-wrapper {
      padding-bottom: map-get($gutters, 'xxs');
      @include small-only() {
        width: 100%;
      }
    }
  }
}
