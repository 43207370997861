.AppLayoutContainer {
  display: flex;
  flex-direction: row;
  background: $gray-1;
  width: 100%;

  &__content {
    width: calc(100% - #{$products-bar-width});
    box-sizing: border-box;
    min-height: calc(100vh - 1px);
  }
}
