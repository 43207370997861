.AddSocialAccountButton {
  @extend .AccountDropdown__button;
  border-top: 0;

  &__icon {
    @extend .AccountDropdown__button-icon;

    &-svg {
      @include make-square(38px, '!important');
    }
  }

  overflow: hidden;
  padding-left: map-get($gutters, 'xxs');
  text-transform: none;
  font-weight: normal;


  &--minified {
    border-top: 0 !important;
    width: 44px;
    padding: 0;
  }

  &--full {
    margin-top: map-get($gutters, 'xxs');
    padding-top: map-get($gutters, 'l');
    padding-bottom: map-get($gutters, 's');
  }
}
