.StandardLineChartTooltip {
  padding: map-get($gutters, 's');
  background-color: $white;
  box-shadow: 0px 0px 0px 1px rgba($black, 0.06), 0px 8px 8px -4px rgba($black, 0.08), 0px 24px 32px -12px rgba($black, 0.08);
  border-radius: $border-radius-2;

  &--line {
    padding-bottom: 0;
  }

  &__metric {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $gray-2;
    padding-top: map-get($gutters, 'xs');

    &:first-of-type {
      border-top: none;
      padding-top: 0;
      padding-bottom: map-get($gutters, 'xs');
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &-indicator {
      margin-left: auto;
      font-weight: bold;
      color: $black;
      padding-left: map-get($gutters, 'xs');

      &--growth {
        color: $green;
      }

      &--loss {
        color: $red;
      }
    }
  }
}
