.CommentItem {
  display: flex;
  padding: map-get($gutters, 's') map-get($gutters, 'm');
  position: relative;

  &:hover, &__hovered {
    background: $gray-1;
  }
  
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__content {
    white-space: pre-wrap;
    word-break: break-all;
  }

  &__image {
    @include make-square(40px);
    margin-right: map-get($gutters, 's');
    display: flex;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    flex-grow: 1;
    height: 20px;
    align-items: center;
  }

  &__name {
    color: $black;
    margin-right: map-get($gutters, 'xxxs');
    max-width: 150px;
    display: inline-block;
    word-break: break-word;
    line-height: 16px;
  }

  &__time {
    color: $gray-6;
  }

  &__toggler {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
