.Teams {
  @include medium-up() {
    padding: 40px 50px 50px;
  }

  &__modal-content {
    box-sizing: border-box;

    @include medium-up() {
      max-width: $add-team-modal-max-width;
      padding: 64px 60px 60px 60px;
    }

    p {
      color: $gray-6;
      text-align: center;

      @include medium-up() {
        text-align: left;
      }
    }

    h3 {
      text-align: center;

      @include medium-up() {
        text-align: left;
      }
    }
  }

  &__modal-input {
    margin-top: $margin-4;
  }

  &__modal-footer {
    display: flex;
    justify-content: flex-end;
    box-shadow: none;

    @include medium-up() {
      padding-right: map-get($gutters, 'xxl');
      padding-left: map-get($gutters, 'xxl');
      box-shadow: inset 0px 0.5px 0px $gray-4;
    }
  }

  &__modal-update-button {
    width: 100%;

    @include medium-up() {
      margin-left: 10px;
      width: auto;
    }
  }

  &__table {
    margin-top: $margin-5;

    .Table__head-column,
    .Table__column {
      &:last-child {
        width: 100px;
      }
    }

    &-column {
      cursor: pointer;

      .Table__column-inner {
        align-items: center;
        min-height: 37px;
      }

      &:last-child {
        .Table__column-inner {
          text-transform: capitalize;
        }
      }
    }

    &-row {
      &--pending {
        color: $gray-6;
      }
    }
  }

  &__chip {
    position: relative;
    top: 2px;
    margin-right: map-get($gutters, 'xxs');
    display: flex;
    padding: 9px 11px;
    color: $gray-5;
  }

  &__cta-paragraph {
    color: $gray-6;
  }

  &__dropdown {
    width: 100%;
    text-align: right;

    .DropdownButton__dropdown {
      z-index: auto;
    }
  }

  &__window {
    width: calc(100% + 115px);
  }
}
