.SchedulePostModal {
  width: $schedule-post-modal-width;
  overflow: visible;

  @include small-only() {
    position: absolute;
    width: 100vw;
    top: 1px + $margin-3;
  }

  &__body {
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
  }

  &__social-accounts {
    display: flex;
    margin-bottom: map-get($gutters, 'm');
    &-add-account {
      height: auto;

      &-icon-svg {
        @include make-square(42px, '!important');
        background-color: $gray-2;
        border-radius: 50%;
      }
    }
    &__item--placeholder {
      opacity: 0.5;
    }
  }

  &__date-picker {
    &-button {
      margin-left: map-get($gutters, 's');
    }
    &-icon {
      margin-right: 0;
    }
  }

  &__submit-button {
    min-width: 140px;
    height: 49px;
  }

  &__submit-wrapper {
    z-index: unset;
  }

  &__spinner {
    @include make-square(18px);
    color: $white;
  }

  &__error-block-content {
    display: inline;
  }

  &__due-date-error {
    color: $red;
    margin-top: map-get($gutters, 'xxs');
  }

  &__footer {
    padding: map-get($gutters, 'm') map-get($gutters, 'l');
    &__autopost {
      display: flex;
      align-items: center;
      &__icon {
        margin-right: map-get($gutters, 'xxxs');
      }
    }
  }
}
