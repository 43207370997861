.BasicDisplayError {
  &__error-block {
    background: transparent;
    padding: map-get($gutters, 'xxs') 0;

    &__content {
      display: block;
    }
  }
}
