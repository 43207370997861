.SocialAccountsList {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: map-get($gutters, 'xxs') map-get($gutters, 'xxs');

  @include medium-up() {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    padding: 0 map-get($gutters, 'xxs');
  }
  background: $white;
  box-sizing: border-box;
  box-shadow: inset -1px 0px 0px $gray-2;

  &__loadable {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 24px;
  }

  &__list {
    @include medium-up() {
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-2;
    }
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 64px;

    @include medium-up() {
      width: 100%;
    }
  }

  &__settings {
    margin-left: auto;
    padding-right: map-get($gutters, 'xxs');

    &-icon {
      border-right: 0;
      color: $gray-6;
    }
  }

  &__add-account-button {
    margin-top: 0;
    @include medium-up() {
      margin-top: map-get($gutters, 's');
    }

    &-icon-svg {
      @include small-only() {
        @include make-square(32px, '!important');
      }
    }
  }
}
