$account-toggle: '.AccountToggle';

#{$account-toggle} {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: 40px;

  &__text {
    text-align: left;
    display: flex;
    flex-direction: column;
    min-width: 120px;
  }

  &__icon {
    margin-left: auto;
    color: $white;
  }
}
