@include with-side-photo-container('.ForgotPasswordContainer', '../../assets/images/forgot-password-bg.png') {
  .ForgotPasswordContainer {
    &__title {
      margin-bottom: 0;
    }

    &__input {
      margin-top: map-get($gutters, 'xl');
    }

    &__submit {
      margin-top: $margin-1 !important;
    }
  }
}
