$toggler-width: 48px;
$toggler-height: 20px;

.Toggler {
  width: $toggler-width;
  height: $toggler-height;
  position: relative;
  border: 2px solid $gray-3;
  border-radius: 2px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 66%;
    height: $toggler-height;
    background: $gray-3;
    right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 33%;
    height: $toggler-height;
    background: $white;
  }

  &--checked {
    border: 2px solid $black;
    &:before {
      left: 0;
      background: $black;
    }
    &:after {
      right: 0;
    }
  }
}
