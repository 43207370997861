@include with-side-photo-container('.ResetPasswordContainer', '') {
  .ResetPasswordContainer {
    &__wrapper {
      width: 100%;
    }

    &__input {
      margin-top: map-get($gutters, 'xl');
    }

    &__submit {
      margin-top: $margin-1 !important;
    }
  }
}
