.Loadable {
  position: relative;

  &--curtain {
    &.Loadable--loading {
      &.Loadable:after {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($white, .8);
      }
    }
  }

  &--hide-content {
    &.Loadable--loading {
      &.Loadable:after {
        background-color: $white;
        z-index: $zindex-loadable-content;
      }
    }
  }

  &--dark {
    .Spinner {
      color: $white;
    }
    &.Loadable--hide-content {
      &.Loadable--loading {
        &.Loadable:after {
          background-color: $black;
        }
      }
    }
  }

  &__loader {
    position: absolute !important;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: $zindex-loadable;
  }

  &__error {
    padding: 100px;
    text-align: center;
  }
}
