.SpaceGridActionBarGroup {
  margin: 0 0 0 map-get($gutters, 'xxs');

  &:first-of-type {
    margin-left: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    background-color: $white;
    border-radius: $border-radius-3;
  }

  &__button {
    min-width: map-get($gutters, '60');
    height: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border-radius: $border-radius-3;

    .SpaceGridActionBarGroup__icon {
      opacity: 0.7;
    }

    &:hover {
      .SpaceGridActionBarGroup__icon {
        opacity: 1;
      }
    }
  }
}
