.Paywall {
  &__modal {

    &-title {
      font-size: 26px;
      margin-bottom: 8px;
    }

    &-subtitle {
      margin-top: 0;
      font-size: 17px;
    }

    &-body {
      margin-top: $margin-2 !important;
      text-align: center;

      @include medium-up() {
        max-width: 960px !important;
      }
    }
  }

  &__tiles {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .PaywallTile {
      height: 420px;

      &:last-child {
        margin-right: 0;
      }
    }

    .slick-track {
      width: 960px !important;

      .PaywallTile {
        margin: 0 auto;
      }

      @include medium-up() {
        width: auto;
      }
    }
  }

}
