$padding-s: map-get($gutters, 's');
$padding-xs: map-get($gutters, 'xs');

.SpaceLayoutChooser__tiles {
  @include make-scrollable-vertically();

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 420px;
}

.SpaceLayoutChooser__tile {
  width: 343px;
  margin-bottom: $padding-s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: $padding-xs;
  border-radius: $border-radius-1;
  transition: .2s background-color ease;

  &:hover {
    background-color: $gray-1;
  }

  &-image {
    @include make-square(100%);
    opacity: 0.8;
    transition: .2s opacity ease;

    &-wrapper {
      width: 96px;
      height: 72px;
      border-radius: $border-radius-2;
    }

    &-placeholder {
      top: 0;
      left: 0;
    }
  }

  &-button {
    @include make-square(48px);
    margin-left: auto;
    padding: 0;

    &--edit-mode {
      @include make-square(30px);
      background: $white;
      border: 2px solid $gray-3;
      right: 9px;
      position: relative;
    }
  }
}
