$cell-height: 11px;

.HeatmapWidget {
  color: $gray-6;
  font-size: 14px;

  &__row {
    display: flex;
  }

  &__cell {
    flex: 1;
    height: $cell-height;
    background: $gray-2;
    border-radius: $border-radius-05;
    margin: map-get($gutters, 'atomic');

    &--level-1 {
      background: $gray-3;
    }

    &--level-2 {
      background: $blue-2;
    }

    &--level-3 {
      background: $blue-3;
    }

    &--level-4 {
      background: $blue-4;
    }

    &--level-5 {
      background: $blue-5;
    }

    &--level-6 {
      background: $blue-6;
    }

    &--level-7 {
      background: $blue-7;
    }
  }

  &__day-label-cell {
    flex: 1;
    text-align: center;
    margin: map-get($gutters, 'xxxs');
  }

  &__hour-label-cell {
    padding-right: map-get($gutters, 'xxs');
    width: 42px;
    height: $cell-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
