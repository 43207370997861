.WithAccountsOnly {

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__accounts {
        width: 376px;
    }

    &__loadable {
        @include make-square(100%);
    }
    
  }
  