$padding-l: map-get($gutters, 'l');

.RenameSpaceModal {
  width: $rename-space-modal-width;

  &__heading {
    margin-top: 0;
  }

  &__body {
    padding: $padding-l;
  }

}
