.SiteHandle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;

    &__wrapper {
        border-radius: 4px;
        background-color:#F5F5F5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        height: 100%;
        width: 100%;
        padding: 0 14px;
    }

    &__handle {
        display: inline-flex;
        position: relative;
        align-items: center;
        overflow: hidden;
        &-site-domain {
            color: #888888
        }
        &-edit {
            cursor: pointer;
        }
        &-copyicon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            padding: 0 8px;
            cursor: pointer;
            img {
                display: block;
            }
        }
    }

}