.SocialAccountInfo {
  &__title {
    text-transform: uppercase;
    font-size: 12px;
  }

  &__row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--notClickable {
      cursor: default;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__title {
      font-weight: 700;
    }
  }

  &__icon {
    color: $gray-4;
  }
}
