.IntegrationAlert {
  top: 0;
  left: 0;
  width: auto;

  &--not-found {
    background: $white;
    border: 1px solid $white;
    border-bottom-color: $gray-2;
  }

  &__info {
    width: 100%;
    display: flex;
    color: $black;

    &__icon {
      position: relative;
      top: map-get($gutters, 'xxxxs');
    }

    &__text {
      margin-left: $margin-1;
    }
  }
}
