.UnreadIndicator {
  display: flex;
  position: relative;

  &__indicator {
    @include make-indicator-circle();
    border: 2px solid $black;
    position: absolute;
    top: 1px;
    left: 52%;

    &--white {
      border-color: $white;
    }
  }
}
