$selectable-list-gutter: map-get($gutters, 'xxs');

.SpaceChooser {
  &__label {
    @extend .Input__label;
  }

  &__warning {
    color: $yellow;
    margin-top: $margin-1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__warning-icon {
    width: 50px;
    margin-right: $margin-1;
  }

  &__not-selected {
    color: $gray-4;
  }

  &__message {
    @extend .Input__message;
  }

  &--error {
    .SpaceChooser__message {
      color: $red;
    }
  }

  &__selectable-list {
    @include make-scrollable-vertically;

    border-top: 1px solid $gray-2;
    margin-top: $selectable-list-gutter;
    margin-bottom: $selectable-list-gutter;
    padding-top: $selectable-list-gutter;
    padding-bottom: $selectable-list-gutter;
    height: 40vh;
  }
}
