$social-accounts-picker: '.SocialAccountsPicker';

#{$social-accounts-picker} {
  display: flex;

  &__option {
    margin-top: map-get($gutters, 'xxxxs') !important;
  }

  &__item {
    color: $gray-6;
    padding-left: map-get($gutters, 'xxs');

    &--placeholder {
      opacity: 0.5;
    }

    &-icon {
      margin-right: map-get($gutters, 'xxs');
    }

    &-icon-wrapper {
      display: block;
    }

    &-content {
      display: block;
    }

    &--dark {
      background: $gray-2;
      border-radius: $border-radius-2;
    }
  }

  &--minified {
    @include make-scrollable-horizontally;
    flex-direction: row;

    #{$social-accounts-picker}__option {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    #{$social-accounts-picker}__item {
      padding: 0 0 0 map-get($gutters, 'xxs');
      background: transparent;

      &-icon-wrapper,
      &-content,
      &-error {
        display: none;
      }
    }
  }

  &--full {
    width: 100% !important;
    margin-top: map-get($gutters, 'xxs');
    flex-direction: column;
    overflow-x: hidden;
  }
}
