.InstagramDetailsModal {
  width: 732px;
  overflow: visible;

  &__datepicker {
    border: 1px solid transparent;

    &--error {
      border: 1px solid $red;
    }
  }

  &__datepicker__error-message {
    color: $red;
    font-size: 12px;
    font-weight: 400;
  }
  
  &__postTime {
    &__button {
      width: 100%;
      background-color: $gray-2;
      color: $black;
  
      &-icon {
        border: none;
        margin-right: 0;
      }
  
      &-iconEnd {
        margin-left: 0;
      }
    }
  }

  &__unscheduleButton {
    display: flex;
    align-items: center;
    margin-left: $margin-1;

    .Button {
      height: 40px;
    }
  }

  &__body {
    padding: map-get($gutters, 'l');
  }

  &__footer {
    height: 49px;
  }

  &__submit {
    height: 49px;
  }
}
